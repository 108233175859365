import { Info } from '../types'

const select_info: Info[] = [
  'Deposit',
  'Withdraw',
  'Reject(Deposit)',
  'Reject(Withdraw)',
  'Deposit Agent',
]

export default select_info
