import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
  // ShieldExclamationIcon,
  SparklesIcon,
  // XCircleIcon,
  PhoneArrowUpRightIcon,
  // PhoneXMarkIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import Nav from '../types/nav';
import nav_path from './nav-path';

const all_nav: Nav[] = [
  {
    name: 'Cross Player',
    icon: ExclamationTriangleIcon,
    href: nav_path.dashboard.cross_player,
  },

  {
    name: 'Cross IP',
    icon: SparklesIcon,
    href: nav_path.dashboard.cross_ip,
  },

  {
    name: 'Follow Up',
    icon: PhoneIcon,
    href: nav_path.dashboard.follow_up,
  },

  {
    name: 'Follow Up No Deposit',
    icon: PhoneArrowUpRightIcon,
    href: nav_path.dashboard.follow_up_no_deposit,
  },

  // {
  //   name: 'Follow Up New Member',
  //   icon: PhoneXMarkIcon,
  //   href: nav_path.dashboard.follow_up_new,
  // },

  {
    name: 'Find Player',
    icon: MagnifyingGlassIcon,
    href: nav_path.dashboard.find_player,
  },
];

export default all_nav;
