const pageArray = (currentPage: number, totalPage: number) => {
  const resArr = []
  if (totalPage < 10) for (let i = 1; i < totalPage + 1; i++) resArr.push(i)

  if (totalPage >= 10) {
    if (currentPage - 4 <= 0) resArr.push(1, 2, 3, 4, 5, 6, 7, 8, 9)

    if (currentPage + 4 >= totalPage) {
      let i = totalPage

      while (resArr.length < 9) {
        resArr.push(i)
        i--
      }

      resArr.reverse()
    }

    if (currentPage - 4 > 0 && currentPage + 4 < totalPage) {
      let i = currentPage - 4

      while (resArr.length < 9) {
        resArr.push(i)
        i++
      }
    }
  }

  return resArr
}

export default pageArray
