const get_trendlines = (data: any[]) => {
  const n = data.length
  const sumXY = data.reduce((acc, val, i) => acc + val * (i + 1), 0)
  const sumX = data.reduce((acc, _val, i) => acc + (i + 1), 0)
  const sumY = data.reduce((acc, val) => acc + val, 0)
  const sumXSquare = data.reduce((acc, _val, i) => acc + (i + 1) ** 2, 0)
  const sumXSquared = sumX ** 2
  const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumXSquared)
  const offset = (sumY - slope * sumX) / n
  const y = (x: number) => slope * x + offset
  return data.map((_, i) => y(i + 1))
}

export default get_trendlines
