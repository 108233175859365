import { DateTime } from 'luxon'
import { errorMessage } from '../Helper'

interface FetchStatisticsData {
  start_date: DateTime
  end_date: DateTime
  signal: AbortSignal
}

const fetch_deposit_average = async ({
  start_date,
  end_date,
  signal,
}: FetchStatisticsData) => {
  const uri = `/api/transaction/form-average?date_start=${start_date.toFormat(
    'yyyy-MM-dd'
  )}&date_end=${end_date.toFormat('yyyy-MM-dd')}`

  const response = await fetch(uri, { signal })
  const res = await response.json()
  if (!response.ok) throw new Error(errorMessage(res) || res.message)
  return { data: res.data }
}

export default fetch_deposit_average
