const errorMessage = (error: any) => {
  if (error?.errors && error?.errors.length)
    return `${error?.errors[0].message}${
      error?.errors[0].field ? ` at ${error?.errors[0].field}` : ''
    }`
  else if (error?.message) return error?.message
  else return undefined
}

export default errorMessage
