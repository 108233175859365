import { useState } from 'react';

const TagsInput = (props: any) => {
  const [tags, setTags] = useState(props.tags || []);
  const removeTags = (indexToRemove: any) => {
    setTags([...tags.filter((_: any, index: any) => index !== indexToRemove)]);
  };
  const addTags = (event: any) => {
    if (event.target.value !== '') {
      setTags([...tags, event.target.value]);
      props.selectedTags([...tags, event.target.value]);
      event.target.value = '';
    }
  };
  return (
    <div className='flex flex-wrap h-[36px] w-[720px] p-[8pxfocus-within:border-white overflow-y-auto'>
      <ul className='flex flex-wrap'>
        {tags.length > 0 &&tags.map((tag: any, index: any) => (
          <li
            key={index}
            onClick={() => removeTags(index)}
            className='w-auto h-[20px] flex items-center justify-center py-[18px] list-none rounded-md mb-[8px] mr-[8px] bg-teal-600 text-slate-100 font-medium text-sm'
          >
            <span className='mx-[8px]'>{tag}</span>
          </li>
        ))}
      </ul>
      <input
        className='flex flex-1 border-none h-[36px] p-[12px] focus:outline-transparent pb-3'
        type='text'
        onKeyUp={event => (event.key === 'Enter' ? addTags(event) : null)}
        placeholder='Masukkan referral yang ingin dicari lalu tekan enter ...'
      />
    </div>
  );
};

export default TagsInput;
