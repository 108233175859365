import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { MobileMenu, MobileTopNav, Sidebar } from './Components/'
import { MobileMenuOpenCtx } from './context'
import useTokenValidation from '../../Hooks/use-token-validation'

const Dashboard: React.FC = () => {
  useTokenValidation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
      <MobileMenuOpenCtx.Provider value={{ mobileMenuOpen, setMobileMenuOpen }}>
        <div className='flex h-full bg-gray-50 overflow-hidden'>
          <MobileMenu />
          <Sidebar />

          <div className='flex min-w-0 flex-1 flex-col overflow-hidden'>
            <MobileTopNav />

            <main className='overflow-auto min-h-screen'>
              <Outlet />
            </main>
          </div>
        </div>
      </MobileMenuOpenCtx.Provider>
    </>
  )
}

export default Dashboard
