import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
// import { DateTime } from 'luxon';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames, errorMessage } from '../../../../Helper';
import { WebsiteName, nav_path } from '../../../../constant';
import { CrossIPModel } from '../../../../types/cross-ip';
import Pagination from './Pagination';
import { FetchModel, FetchType } from '../../../../types';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import { CloudArrowDownIcon } from '@heroicons/react/20/solid';

interface CrossIPState {
  selected: number;
  deposit_page: number;
}

type CrossIPAction =
  | { type: 'SET_SELECTED'; payload: number }
  | { type: 'SET_DP_PAGE'; payload: number };

const CrossIP = () => {
  const navigate = useNavigate();

  const [cross_ips, set_cross_ips] = useState<CrossIPModel[]>([]);
  const [page, set_page] = useState(1);
  const [total_data, set_total_data] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [fetching, setfetching] = useState(false);
  const [fetchAllowed, set_fetch_allowed] = useState(false);

  const [{ deposit_page, selected }, dispatch] = useReducer(
    (state: CrossIPState, action: CrossIPAction): CrossIPState => {
      switch (action.type) {
        case 'SET_SELECTED':
          return {
            ...state,
            deposit_page: 1,
            selected: action.payload,
          };

        case 'SET_DP_PAGE':
          return {
            ...state,
            deposit_page: action.payload,
          };

        default:
          return state;
      }
    },

    {
      selected: 0,
      deposit_page: 1,
    }
  );

  const getLastFetch = useCallback(async () => {
    try {
      const uri = '/api/fetch/get-last';
      const method = 'POST';
      const headers = { 'Content-Type': 'application/json' };
      const type = 'IP' as FetchType;
      const body = JSON.stringify({ type });
      const response = await fetch(uri, { method, headers, body });

      const res: {
        message: string;
        data: { lastFetch: { [k in WebsiteName]: FetchModel } };
      } = await response.json();

      if (!response.ok) throw new Error(errorMessage(res) || res.message);

      if (
        Object.values(res.data.lastFetch)?.some(fetch => !fetch?.success) ||
        Object.values(res.data.lastFetch).length !==
          Object.values(WebsiteName).length
      ) {
        set_fetch_allowed(true);
      }
    } catch (error: any) {
      alert(
        error.message || `Terjadi kesalahan ketika mengecek tarikan terakhir...`
      );
    }
  }, []);

  const fetchCrossIp = async () => {
    console.log('Trigger fetching cross ip');

    try {
      if (!fetchAllowed) return alert('Data Player hari ini sudah ditarik!');
      if (fetching) return;
      setfetching(true);
      const uri = '/api/cross-ip/fetch';
      const method = 'POST';
      const headers = { 'Content-Type': 'application/json' };
      const response = await fetch(uri, { method, headers });
      const res = await response.json();
      if (!response.ok)
        throw new Error(errorMessage(res.message) || res.message);
      alert(res.message);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika fetch data Cross Ip...`);
    } finally {
      setfetching(false);
      getLastFetch();
    }
  };

  useEffect(() => {
    const abort_controller = new AbortController();

    const fetch_cross_ip = async () => {
      try {
        setfetching(true);
        const response = await fetch(
          `/api/cross-ip?page=${page}&login_page=${deposit_page}`,
          { signal: abort_controller.signal }
        );

        const res: {
          message: string;
          data: {
            cross_ips: CrossIPModel[];
            total_data: number;
            total_page: number;
          };
        } = await response.json();

        if (response.status === 401) {
          alert(`Sesi Anda telah berakhir...`);
          navigate(nav_path.login);
        }

        if (!response.ok) throw new Error(errorMessage(res) || res.message);
        set_cross_ips(res.data.cross_ips);
        set_total_data(res.data.total_data);
        set_total_page(res.data.total_page);
        setfetching(false);
      } catch (error: any) {
     
        if (error.name === 'AbortError') return;
        alert(error.message);
        setfetching(false);
      }
    };

    fetch_cross_ip();

    return () => {
      abort_controller.abort();
    };
  }, [navigate, page, deposit_page]);

  useEffect(() => {
    getLastFetch();
  }, [getLastFetch,fetching]);

  return (
    <>
      <div className='p-5'>
        <div className='flex flex-row '>
          <h1 className='text-3xl font-bold'>Cross IP</h1>
          <button
            className={classNames(
              'ml-auto inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer active:opacity-90',

              fetchAllowed
                ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 text-white'
                : 'bg-gray-300 text-gray-100'
            )}
            onClick={fetchCrossIp}
            disabled={!fetchAllowed}
          >
            {fetching ? (
              <LoadingIndicator colorScheme={'light'} />
            ) : (
              <>
                <CloudArrowDownIcon className='-ml-1 mr-2 h-5 w-5' /> Fetch
              </>
            )}
          </button>
        </div>

        <div className='flex items-start mt-5 space-x-5'>
          <div className='bg-white text-teal-900 rounded-md shadow text-sm w-1/4'>
            {cross_ips.length &&
              cross_ips.map((cross_ip, index) => (
                <div
                  key={cross_ip._id}
                  className={classNames(
                    'border-b border-gray-300 last-of-type:border-none py-4 pl-5 pr-10 cursor-pointer hover:bg-teal-100 truncate',
                    index === selected ? 'bg-teal-100' : ''
                  )}
                  onClick={() =>
                    dispatch({
                      type: 'SET_SELECTED',
                      payload: index,
                    })
                  }
                >
                  {cross_ip.ip || ''}
                </div>
              ))}

            <Pagination
              page={page}
              set_page={set_page}
              total_data={total_data}
              total_page={total_page}
            />
          </div>

          <div className='p-5 rounded-md shadow-lg bg-white w-5/12'>
            {
              <>
                <h2 className='text-2xl font-bold'>
                  {cross_ips[selected]?.ip || ''}
                </h2>

                <div className='mt-5'>
                  <div className='flex items-center justify-between'>
                    <h2 className='text-xl font-bold'>List Username</h2>

                    <div className='flex'>
                      <button
                        className='active:opacity-60'
                        onClick={() => {
                          if (deposit_page === 1) return;

                          dispatch({
                            type: 'SET_DP_PAGE',
                            payload: deposit_page - 1,
                          });
                        }}
                      >
                        <ArrowLeftCircleIcon className='h-8 w-8' />
                      </button>

                      <button
                        className='active:opacity-60'
                        onClick={() => {
                          if (cross_ips[selected]?.login.length < 10) {
                            return;
                          }

                          dispatch({
                            type: 'SET_DP_PAGE',
                            payload: deposit_page + 1,
                          });
                        }}
                      >
                        <ArrowRightCircleIcon className='h-8 w-8' />
                      </button>
                    </div>
                  </div>

                  <div className='mt-3 overflow-hidden rounded-md shadow text-sm'>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <th className='bg-teal-900 text-teal-50 p-3 first-of-type:pl-6 last-of-type:pr-6'>
                            Time
                          </th>

                          <th className='bg-teal-900 text-teal-50 p-3 first-of-type:pl-6 last-of-type:pr-6'>
                            Username
                          </th>
                          <th className='bg-teal-900 text-teal-50 p-3 first-of-type:pl-6 last-of-type:pr-6'>
                            WEB
                          </th>
                        </tr>
                      </thead>

                      <tbody className='text-gray-500 bg-teal-50'>
                        {cross_ips[selected]?.login.map(
                          (loginItem, loginIndex) => (
                            <tr key={loginIndex}>
                              <td className='text-center p-3 first-of-type:pl-6 last-of-type:pr-6 font-mono font-light'>
                                {new Date(loginItem.time).toLocaleString()}
                              </td>
                              <td className='text-center p-3 first-of-type:pl-6 last-of-type:pr-6 font-mono font-light'>
                                {loginItem.username}
                              </td>
                              <td className='text-center p-3 first-of-type:pl-6 last-of-type:pr-6 font-mono font-light'>
                                {loginItem.web}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      {fetching && (
        <div className='bg-teal-900 bg-opacity-75 fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center z-30'>
          <div className='text-white'>
            <div className='text-center'>
              <LoadingIndicator colorScheme='light' />
            </div>
            Now Loading...
          </div>
        </div>
      )}
    </>
  );
};

export default CrossIP;
