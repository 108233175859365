import { DateTime } from 'luxon'
import { errorMessage } from '../Helper'

interface FetchStatisticsData {
  start_date: DateTime
  end_date: DateTime
  abort_controller: AbortController
}

const fetch_statistics_data = async ({
  start_date,
  end_date,
  abort_controller,
}: FetchStatisticsData) => {
  const uri = `/api/transaction/daily-total?start_date=${start_date.toFormat(
    'dd-MM-yyyy'
  )}&end_date=${end_date.toFormat('dd-MM-yyyy')}`

  const response = await fetch(uri, { signal: abort_controller.signal })
  const res = await response.json()
  if (!response.ok) throw new Error(errorMessage(res) || res.message)
  return { data: res.data }
}

export default fetch_statistics_data
