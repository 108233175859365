import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { LeafIcon } from '../../../Assets';
import Select from '../../../Components/Select';
import { errorMessage } from '../../../Helper';
import { WebsiteName } from '../../../constant';
import FollowUpModel from '../../../types/follow-up';
import LoadingIndicator from '../../../Components/LoadingIndicator';

const page_size = 20;

const NoData = () => (
  <tr className='opacity-50 w-full text-center py-20 h-full'>
    <td colSpan={99} className='py-20'>
      <div className='w-24 m-auto'>
        <img src={LeafIcon.default} alt='No statements...' className='w-full' />
      </div>

      <div>Tidak ada transaksi...</div>
    </td>
  </tr>
);

enum NotesType {
  EMPTY = 'Empty Notes',
  QUERY = 'Query Notes',
}

const FollowUp = () => {
  const [follow_up, set_follow_up] = useState<FollowUpModel[]>([]);
  const [page, set_page] = useState(1);
  const [total_data, set_total_data] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [agent_name, set_agent_name] = useState('');
  const [loading_finish, set_loading_finish] = useState<string[]>([]);
  const [notes_query, set_notes_query] = useState('');
  const [notes_type, set_notes_type] = useState<null | NotesType>(null);
  const [fetching, set_is_fetching] = useState(false);

  const [selected_web, set_selected_web] = useState<WebsiteName>(
    Object.values(WebsiteName)[0]
  );

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      try {
        set_is_fetching(true);
        const response = await fetch(
          `/api/player/follow-up?page=${page}&web=${selected_web}&filter_notes=${
            notes_type === NotesType.QUERY
          }&notes_query=${notes_query}&empty_notes=${
            notes_type === NotesType.EMPTY
          }`,

          { signal: controller.signal }
        );

        const res: {
          message: string;
          data: FollowUpModel[];
          total_data: number;
          total_page: number;
        } = await response.json();

        console.log(res);
        if (!response.ok) throw new Error(errorMessage(res) || res.message);
        set_follow_up(res.data);
        set_total_data(res.total_data);
        set_total_page(res.total_page);
        set_is_fetching(false);
      } catch (error: any) {
        set_is_fetching(false);
        if (error.name === 'AbortError') return;
        alert(error.message);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [page, selected_web, notes_query, notes_type]);

  return (
    <>
      <div className='p-5'>
        <div className='flex justify-between'>
          <h1 className='text-3xl font-bold'>Follow Up</h1>

          <div className='flex space-x-2'>
            {notes_type === NotesType.QUERY && (
              <DebounceInput
                type='text'
                className='shadow-sm focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded-md'
                value={notes_query}
                placeholder='Filter Notes...'
                onChange={e => {
                  set_notes_query(e.target.value);
                }}
                debounceTimeout={750}
              />
            )}

            <Select
              displayValue={notes_type || 'All Notes'}
              nullOptions={true}
              nullMessage='All Notes'
              onChange={set_notes_type}
              options={Object.values(NotesType)}
              value={notes_type}
            />

            <Select
              displayValue={selected_web || 'ALL'}
              nullOptions={false}
              onChange={set_selected_web}
              options={Object.values(WebsiteName)}
              value={selected_web}
            />

            <input
              type='text'
              className='shadow-sm focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded-md'
              value={agent_name}
              placeholder='Agent Name'
              onChange={e => {
                set_agent_name(e.target.value);
              }}
            />
          </div>
        </div>

        <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 rounded-md h-full overflow-y-scroll scrollbar-hide mt-4'>
          <table className='min-w-full divide-y divide-gray-300 shadow text-sm'>
            <thead className='bg-gray-50'>
              <tr className='font-semibold text-gray-900'>
                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6 text-left'>
                  Player ID
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6'>
                  Break Avg.
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6'>
                  Curr. Break
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6'>
                  Status
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6'>
                  Last Deposit
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6 text-left'>
                  Nama
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6 text-left'>
                  No. HP
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6 text-left'>
                  Notes
                </th>
              </tr>
            </thead>

            <tbody className='divide-y divide-gray-200 bg-white'>
              {follow_up?.length ? (
                follow_up.map(player => (
                  <tr key={player._id} className='text-gray-500'>
                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6'>
                      {player.playerId}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 text-center'>
                      {player.break_average}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 text-center'>
                      {player.current_break.toFixed(2)}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 text-center'>
                      {player.is_vvip
                        ? 'VVIP'
                        : player.is_vip
                        ? 'VIP'
                        : 'Non-VIP'}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 text-center font-mono font-light'>
                      {DateTime.fromISO(player.last_deposit).toFormat(
                        'd MMMM yyyy - HH:mm:ss'
                      )}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6'>
                      {player.player_detail.namaRekening}
                    </td>

                    <td
                      className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 cursor-pointer hover:text-teal-500'
                      onClick={() => {
                        window.open(
                          `https://api.whatsapp.com/send/?phone=62${player.player_detail.phone.slice(
                            1
                          )}&text=Halo bos ${
                            player.player_detail.namaRekening
                              .split(' ')[0]
                              .slice(0, 1) +
                            player.player_detail.namaRekening
                              .split(' ')[0]
                              .slice(1)
                              .toLowerCase()
                          }! Perkenalkan saya${
                            agent_name ? ' ' + agent_name : ''
                          } dari ${
                            player.player_detail.website
                          }. Saya lihat bosku sudah lama tidak bermain di situs kami ya. Saya hanya ingin mengkonfirmasi saja, apakah ada masalah terkait proses deposit atau permainannya bosku?&type=phone_number&app_absent=0`,
                          '_blank'
                        );
                      }}
                    >
                      {player.player_detail.phone}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 relative'>
                      <DebounceInput
                        debounceTimeout={500}
                        type='text'
                        className='shadow-sm focus:ring-teal-500 focus:border-teal-500 text-sm border-gray-300 rounded w-full'
                        onChange={async e => {
                          await fetch('/api/player/notes', {
                            method: 'PATCH',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                              playerId: player._id,
                              notes: e.target.value.normalize(),
                            }),
                          });

                          set_loading_finish(loading => {
                            loading.push(player._id);
                            return [...loading];
                          });

                          setTimeout(() => {
                            document
                              .getElementById(`${player._id}-finish-loading`)
                              ?.classList.replace('fade-in', 'fade-out');

                            setTimeout(() => {
                              set_loading_finish(loading => {
                                loading.splice(loading.indexOf(player._id), 1);
                                return [...loading];
                              });
                            }, 1000);
                          }, 3 * 1000);
                        }}
                        value={player.player_detail.notes}
                      />

                      {loading_finish.includes(player._id) ? (
                        <CheckCircleIcon
                          className='h-5 w-5 absolute top-4 right-8 text-teal-500 fade-in'
                          id={`${player._id}-finish-loading`}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <NoData />
              )}
            </tbody>
          </table>

          <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
            <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
              <div>
                <p className='text-sm text-gray-700 mr-10'>
                  <span className='font-medium'>
                    {total_data > 0 ? page * page_size - (page_size - 1) : 0}
                  </span>{' '}
                  -{' '}
                  <span className='font-medium'>
                    {page * page_size > total_data
                      ? total_data
                      : page * page_size}
                  </span>{' '}
                  dari <span className='font-medium'>{total_data}</span>
                </p>
              </div>
            </div>

            <div>
              <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
                <button
                  className='relative inline-flex justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => set_page(1)}
                >
                  <ChevronDoubleLeftIcon className='h-5 w-5' />
                </button>

                <button
                  className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => {
                    if (page === 1) return;
                    set_page(page => page - 1);
                  }}
                >
                  <ChevronLeftIcon className='h-5 w-5' />
                </button>

                <button
                  className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => {
                    if (page === total_page) return;
                    set_page(page => page + 1);
                  }}
                >
                  <ChevronRightIcon className='h-5 w-5' />
                </button>

                <button
                  className='relative inline-flex justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => set_page(total_page)}
                >
                  <ChevronDoubleRightIcon className='h-5 w-5' />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {fetching && (
        <div className='bg-teal-900 bg-opacity-75 fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center z-30'>
          <div className='text-white'>
            <div className='text-center'>
              <LoadingIndicator colorScheme='light' />
            </div>
            Now Loading...
          </div>
        </div>
      )}
    </>
  );
};

export default FollowUp;
