import { CloudArrowDownIcon } from '@heroicons/react/20/solid';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';
import { LeafIcon } from '../../../Assets';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import Select from '../../../Components/Select';
import { classNames, errorMessage, thousandSeparator } from '../../../Helper';
import { WebsiteName, nav_path } from '../../../constant';
import { FetchModel, FetchType, Info } from '../../../types';
import { DebounceInput } from 'react-debounce-input';
import { TransactionModel } from '../../../types/transaction';
import select_info from '../../../constant/select-info';

const AREA = { GET_TRANSACTION: 'GET_TRANSACTION' };

const NoTransactions = () => (
  <div className='opacity-50 w-full text-center py-20 h-full'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No statements...' className='w-full' />
    </div>

    <div>Tidak ada transaksi...</div>
  </div>
);

const Transaction = () => {
  const navigate = useNavigate();

  const [fetching, setFetching] = useState(false);
  const [fetchAllowed, setFetchAllowed] = useState(false);
  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [info, set_info] = useState<Info>('Deposit');
  const [mostFrequent, setMostFrequent] = useState<[string, number]>(['', 0]);
  const [highestAmount, setHighestAmount] = useState<[string, number]>(['', 0]);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [aggregate, set_aggregate] = useState<boolean>(false);

  const [date, setDate] = useState(
    DateTime.now().minus({ day: 1 }).toFormat('yyyy-MM-dd')
  );

  const [selectedWeb, setSelectedWeb] = useState<WebsiteName>(
    Object.values(WebsiteName)[0]
  );

  const { promiseInProgress: loadingTrx } = usePromiseTracker({
    area: AREA.GET_TRANSACTION,
  });

  const fetchTransaction = async () => {
    try {
      if (!fetchAllowed) return alert('History koin hari ini sudah ditarik!');
      if (fetching) return;
      setFetching(true);
      const uri = '/api/transaction/fetch';
      const method = 'POST';
      const headers = { 'Content-Type': 'application/json' };
      const response = await fetch(uri, { method, headers });
      if (response.status === 401) navigate(nav_path.login);
      const res = await response.json();
      if (!response.ok)
        throw new Error(errorMessage(res.message) || res.message);
      alert(res.message);
    } catch (error: any) {
      alert(
        error.message || `Terjadi kesalahan ketika fetch data transaksi...`
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    const getLastFetch = async () => {
      try {
        const uri = '/api/fetch/get-last';
        const method = 'POST';
        const headers = { 'Content-Type': 'application/json' };
        const type = 'HISTORY_KOIN' as FetchType;
        const body = JSON.stringify({ type });
        const response = await fetch(uri, { method, headers, body });

        const res: {
          message: string;
          data: { lastFetch: { [k in WebsiteName]: FetchModel } };
        } = await response.json();

        if (!response.ok) throw new Error(errorMessage(res) || res.message);

        if (
          Object.values(res.data.lastFetch)?.some(fetch => !fetch?.success) ||
          Object.values(res.data.lastFetch).length !==
            Object.values(WebsiteName).length
        ) {
          setFetchAllowed(true);
        }
      } catch (error: any) {
        alert(
          error.message ||
            `Terjadi kesalahan ketika mengecek tarikan terakhir...`
        );
      }
    };

    getLastFetch();
  }, []);

  useEffect(() => {
    const abort_controller = new AbortController();

    const get_transactions = async () => {
      try {
        const response = await fetch(
          `/api/transaction?date=${date}&website=${selectedWeb}&info=${info}&page=${currentPage}&aggregate=${aggregate}`,
          { signal: abort_controller.signal }
        );

        if (response.status === 401) navigate(nav_path.login);

        const res: {
          message: string;
          data: {
            transactions: TransactionModel[];
            mostFrequent: [string, number];
            highestAmount: [string, number];
            totalValue: number;
            total: { data: number; page: number };
          };
        } = await response.json();

        if (!response.ok) throw new Error(errorMessage(res) || res.message);
        setTransactions(res.data.transactions);
        setTotalData(res.data.total.data);
        setTotalPage(res.data.total.page);
        setMostFrequent(res.data.mostFrequent);
        setHighestAmount(res.data.highestAmount);
        setTotalValue(res.data.totalValue);
      } catch (error: any) {
        if (error.message?.includes('aborted')) return;
        alert(
          error.message ||
            `Terjadi kesalahan ketika mengambil data transaksi...`
        );
      }
    };

    trackPromise(get_transactions(), AREA.GET_TRANSACTION);

    return () => {
      abort_controller.abort();
    };
  }, [date, selectedWeb, currentPage, info, navigate, aggregate]);

  const stats = [
    {
      name: 'Most Frequent',
      stat: mostFrequent[0],
      previousStat: `${mostFrequent[1]}x`,
      change: '12%',
      changeType: 'increase',
    },

    {
      name: 'Highest Amount',
      stat: highestAmount[0],
      previousStat: `Rp ${thousandSeparator({ value: highestAmount[1] })}`,
      change: '2.02%',
      changeType: 'increase',
    },

    {
      name: 'Total Value',
      stat: `Rp ${thousandSeparator({ value: totalValue.toFixed(0) })}`,
      previousStat: '',
      change: '4.05%',
      changeType: 'decrease',
    },

    {
      name: 'Average',
      stat: `Rp ${thousandSeparator({
        value: Math.round(+totalValue.toFixed() / totalData),
      })}`,
      previousStat: '',
      change: '4.05%',
      changeType: 'decrease',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Transaction - Growth Tracker</title>
      </Helmet>

      <div className='p-5 h-full flex flex-col'>
        <div className='flex justify-between items-center w-full'>
          <h1 className='text-3xl font-bold'>Transaction</h1>

          <div className='flex space-x-2'>
            <div>
              <DebounceInput
                type='text'
                value={currentPage}
                onChange={e => {
                  if (+e.target.value) setCurrentPage(+e.target.value);
                }}
                className='w-12 text-center border bg-white border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm'
                debounceTimeout={500}
              />
            </div>

            <div className='flex items-center border px-3 bg-white relative border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm'>
              <input
                type='checkbox'
                checked={aggregate}
                className='mr-2 focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded checked:bg-teal-500'
                onChange={() => set_aggregate(!aggregate)}
              />{' '}
              Aggregate
            </div>

            <Select
              displayValue={info}
              nullOptions={false}
              onChange={set_info}
              options={select_info}
              value={info}
            />

            <Select
              displayValue={selectedWeb || 'ALL'}
              nullOptions={false}
              onChange={setSelectedWeb}
              options={Object.values(WebsiteName)}
              value={selectedWeb}
            />

            <input
              type='date'
              value={date}
              onChange={e => setDate(e.target.value)}
              className='shadow-sm focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded-md ml-2'
            />

            <button
              className={classNames(
                'inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer active:opacity-90',

                fetchAllowed
                  ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 text-white'
                  : 'bg-gray-300 text-gray-100'
              )}
              onClick={fetchTransaction}
              disabled={!fetchAllowed}
            >
              {fetching ? (
                <LoadingIndicator colorScheme={'light'} />
              ) : (
                <>
                  <CloudArrowDownIcon className='-ml-1 mr-2 h-5 w-5' /> Fetch
                </>
              )}
            </button>
          </div>
        </div>

        <div className='flex mt-4 h-0 flex-grow space-x-4'>
          <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 rounded-md h-full overflow-y-scroll w-fit scrollbar-hide'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 pl-6 text-left text-sm font-semibold text-gray-900'
                  >
                    Player
                  </th>

                  <th
                    scope='col'
                    className='whitespace-nowrap px-2 py-3.5 pr-5 text-right text-sm font-semibold text-gray-900'
                  >
                    Amount
                  </th>

                  {!aggregate && (
                    <th
                      scope='col'
                      className='whitespace-nowrap px-2 py-3.5 pr-5 text-center text-sm font-semibold text-gray-900'
                    >
                      Time
                    </th>
                  )}
                </tr>
              </thead>

              <tbody className='divide-y divide-gray-200 bg-white'>
                {loadingTrx ? (
                  <tr>
                    <td colSpan={11} className='text-center py-8'>
                      <LoadingIndicator colorScheme='dark' />
                    </td>
                  </tr>
                ) : transactions.length ? (
                  transactions.map(transaction => {
                    return (
                      <tr
                        key={transaction.id || transaction.playerId}
                        className='text-gray-500'
                      >
                        <td className='whitespace-nowrap px-2 py-2 pl-6 text-sm'>
                          {transaction.playerId}
                        </td>

                        <td className='whitespace-nowrap px-2 py-2 pr-5 text-sm text-right font-mono font-light'>
                          {thousandSeparator({ value: transaction.amount })}
                        </td>

                        {!aggregate && (
                          <td className='whitespace-nowrap px-2 py-2 pr-5 text-sm text-center font-mono font-light'>
                            {DateTime.fromISO(transaction.time)
                              .setLocale('id')
                              .toFormat('HH:mm:ss')}
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>
                      <NoTransactions />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
              <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
                <div>
                  <p className='text-sm text-gray-700 mr-10'>
                    <span className='font-medium'>
                      {totalData > 0 ? currentPage * 100 - 99 : 0}
                    </span>{' '}
                    -{' '}
                    <span className='font-medium'>
                      {currentPage * 100 > totalData
                        ? totalData
                        : currentPage * 100}
                    </span>{' '}
                    dari <span className='font-medium'>{totalData}</span>
                  </p>
                </div>
              </div>

              <div>
                <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
                  <button
                    className='relative inline-flex justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => setCurrentPage(1)}
                  >
                    <ChevronDoubleLeftIcon className='h-5 w-5' />
                  </button>

                  <button
                    className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => {
                      if (currentPage === 1) return;
                      setCurrentPage(currentPage => currentPage - 1);
                    }}
                  >
                    <ChevronLeftIcon className='h-5 w-5' />
                  </button>

                  <button
                    className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => {
                      if (currentPage === totalPage) return;
                      setCurrentPage(currentPage => currentPage + 1);
                    }}
                  >
                    <ChevronRightIcon className='h-5 w-5' />
                  </button>

                  <button
                    className='relative inline-flex justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => setCurrentPage(totalPage)}
                  >
                    <ChevronDoubleRightIcon className='h-5 w-5' />
                  </button>
                </nav>
              </div>
            </div>
          </div>

          <div>
            <dl className='flex flex-wrap overflow-hidden'>
              {stats.map(item => (
                <div
                  key={item.name}
                  className='px-4 py-5 bg-white shadow rounded-md mb-2 mr-2'
                >
                  <dt className='text-base font-normal text-gray-900'>
                    {item.name}
                  </dt>

                  <dd className='mt-1 flex items-baseline justify-between'>
                    <div className='flex items-baseline text-2xl font-semibold text-teal-600'>
                      {item.stat}
                      <span className='text-sm font-medium text-gray-500 ml-2'>
                        {item.previousStat}
                      </span>
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transaction;
