import {
  ChartBarIcon,
  DocumentArrowUpIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import Nav from '../types/nav'
import nav_path from './nav-path'

const master_nav: Nav[] = [
  {
    name: 'Statistics',
    icon: ChartBarIcon,
    href: nav_path.dashboard.statistics,
  },

  {
    name: 'Transaction',
    icon: DocumentArrowUpIcon,
    href: nav_path.dashboard.transaction,
  },

  { name: 'Users', icon: UserGroupIcon, href: nav_path.dashboard.pemain },

  {
    name: 'User',
    icon: UsersIcon,
    href: nav_path.dashboard.user,
  },
]

export default master_nav
