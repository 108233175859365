const get_slope = (data: number[]) => {
  const n = data.length
  const sumXY = data.reduce((acc, val, i) => acc + val * (i + 1), 0)
  const sumX = data.reduce((acc, _val, i) => acc + (i + 1), 0)
  const sumY = data.reduce((acc, val) => acc + val, 0)
  const sumXSquare = data.reduce((acc, _val, i) => acc + (i + 1) ** 2, 0)
  const sumXSquared = sumX ** 2
  const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumXSquared)
  return slope
}

export default get_slope
