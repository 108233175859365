import { createContext } from 'react'

interface MobileMenuOpenIntf {
  mobileMenuOpen: boolean
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>> | null
}

export const MobileMenuOpenCtx = createContext<MobileMenuOpenIntf>({
  mobileMenuOpen: false,
  setMobileMenuOpen: null,
})
