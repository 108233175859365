import StatisticsContext from '../../../../../Context/StatisticsContext';

import { useContext, useMemo } from 'react';
import { WebsiteName } from '../../../../../constant';
import { classNames, thousandSeparator } from '../../../../../Helper';
import { Websites } from '../../../../../types/website';

const DepositTotal = () => {
  const { chart_data, comparison, selected_web } =
    useContext(StatisticsContext);

  const comparison_map = useMemo(() => {
    const new_map = new Map<Websites[number], number[]>();

    for (const deposit of Object.values(comparison || {})) {
      for (const website of Object.keys(deposit)) {
        new_map.set(website as Websites[number], [
          ...(new_map.get(website as Websites[number]) || []),
          deposit[website as Websites[number]],
        ]);
      }
    }

    return new_map;
  }, [comparison]);

  const total_data = useMemo(() => {
    const data = new Map<Websites[number], number[]>();

    for (const deposit of Object.values(chart_data)) {
      for (const website of Object.keys(deposit)) {
        data.set(website as Websites[number], [
          ...(data.get(website as Websites[number]) || []),
          deposit[website as Websites[number]],
        ]);
      }
    }

    const totals = Object.fromEntries(
      Object.values(WebsiteName).map(website => [
        website,

        {
          total: (data.get(website) || []).reduce((a, b) => a + b, 0),

          growth:
            (((data.get(website) || []).reduce((a, b) => a + b, 0) -
              (comparison_map.get(website) || []).reduce((a, b) => a + b, 0)) /
              (comparison_map.get(website) || []).reduce((a, b) => a + b, 0)) *
            100,
        },
      ])
    );

    const sorted = Object.entries(totals).sort(
      ([, { total: a }], [, { total: b }]) => b - a
    );

    return sorted;
  }, [chart_data, comparison_map]);

  return (
    <div className='bg-white rounded-md overflow-hidden shadow-md space-y-3 mb-5 mr-5'>
      <h2 className='font-bold tracking-widest text-center pt-4 px-10'>
        DEPOSIT TOTAL
      </h2>

      <table className='w-full text-sm'>
        <thead>
          <tr className='text-white'>
            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6  text-left'>
              Website
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6  text-right'>
              Total
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6  text-right'>
              Growth
            </th>
          </tr>
        </thead>

        <tbody>
          {total_data.map(([website, { total, growth }]) => (
            <tr key={website} className='opacity-60'>
              <td
                className={classNames(
                  'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6',
                  selected_web === website
                    ? 'font-bold bg-teal-50'
                    : 'bg-teal-100'
                )}
              >
                {website}
              </td>

              <td
                className={classNames(
                  'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono',
                  selected_web === website
                    ? 'font-medium bg-teal-50'
                    : 'font-light bg-teal-100'
                )}
              >
                {thousandSeparator({ value: Math.round(Math.abs(total)) })}
              </td>

              <td
                className={classNames(
                  'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono',
                  selected_web === website
                    ? 'font-medium bg-teal-50'
                    : 'font-light bg-teal-100'
                )}
              >
                {growth.toFixed(2)}%
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot className='text-teal-50'>
          <tr>
            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-medium'>
              Total
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-mono text-right'>
              {thousandSeparator({
                value: Math.round(
                  Math.abs(
                    total_data.reduce((a, [, { total }]) => a + total, 0)
                  )
                ),
              })}
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-mono text-right'>
              {(
                ((total_data.reduce((a, [, { total }]) => a + total, 0) -
                  [...comparison_map.values()]
                    .map(web =>
                      web.reduce((total, deposit) => total + deposit, 0)
                    )
                    .reduce((a, b) => a + b, 0)) /
                  [...comparison_map.values()]
                    .map(web =>
                      web.reduce((total, deposit) => total + deposit, 0)
                    )
                    .reduce((a, b) => a + b, 0)) *
                100
              ).toFixed(2)}
              %
            </td>
          </tr>

          <tr>
            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 rounded-bl font-medium'>
              Average
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-mono text-right'>
              {thousandSeparator({
                value: Math.round(
                  Math.abs(
                    total_data.reduce((a, [, { total }]) => a + total, 0) /
                      total_data.length
                  )
                ),
              })}
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 rounded-br font-mono text-right'>
              {(
                ((total_data.reduce((a, [, { total }]) => a + total, 0) /
                  total_data.length -
                  [...comparison_map.values()]
                    .map(web =>
                      web.reduce((total, deposit) => total + deposit, 0)
                    )
                    .reduce((a, b) => a + b, 0) /
                    [...comparison_map.values()].length) /
                  ([...comparison_map.values()]
                    .map(web =>
                      web.reduce((total, deposit) => total + deposit, 0)
                    )
                    .reduce((a, b) => a + b, 0) /
                    [...comparison_map.values()].length)) *
                100
              ).toFixed(2)}
              %
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default DepositTotal;
