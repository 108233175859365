import { CheckIcon, WrenchIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Helmet } from 'react-helmet';
import { errorMessage } from '../../../Helper';
import UserModel from '../../../types/user';

const User = () => {
  const [users, set_users] = useState<UserModel[]>([]);
  const [new_username, set_new_username] = useState('');
  const [new_role, set_new_role] = useState('USER');

  useEffect(() => {
    const abort_controller = new AbortController();

    const get_users = async () => {
      const response = await fetch('/api/user/get-all', {
        signal: abort_controller.signal,
      });

      const res = await response.json();
      if (!response.ok) throw new Error(errorMessage(res) || res.message);
      set_users(res.data);
    };

    get_users().catch(error => {
      if (error.name !== 'AbortError') {
        alert(error.message);
      }
    });

    return () => {
      abort_controller.abort();
    };
  }, []);

  const submit_change = async (user: UserModel) => {
    const response = await fetch(`/api/user/${user.id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    });

    const res = await response.json();
    alert(res.message);
  };

  const reset_password = async (user: UserModel) => {
    const response = await fetch(`/api/user/reset-password/${user.id}`, {
      method: 'PATCH',
    });

    const res = await response.json();
    alert(res.message);
  };

  const create_new_user = async () => {
    const response = await fetch('/api/user/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },

      body: JSON.stringify({
        username: new_username,
        password: new_username,
        role: new_role,
      }),
    });

    const res = await response.json();
    alert(errorMessage(res) || res.message);

    set_new_role('');
    set_new_username('');
  };

  return (
    <>
      <Helmet>
        <title>User - Growth Tracker</title>
      </Helmet>

      <div className='p-5 h-full flex flex-col'>
        <div className='flex justify-between items-center w-full'>
          <h1 className='text-3xl font-bold'>User</h1>
          <div className='flex space-x-2'></div>
        </div>

        <div className='flex items-start mt-4 h-0 flex-grow space-x-4'>
          {users.length
            ? users.map(user => (
                <div
                  key={user.username}
                  className='bg-white px-5 py-3 rounded shadow-md hover:shadow-lg transition-shadow flex items-center space-x-4'
                >
                  <div className='space-y-1'>
                    <DebounceInput
                      type='text'
                      className='font-bold text-xl p-0 border-none'
                      value={user.username}
                      onChange={e => {
                        set_users(users => {
                          users.find(
                            u => u.username === user.username
                          )!.username = e.target.value;

                          return [...users];
                        });
                      }}
                      debounceTimeout={1000}
                    />

                    <div>
                      Role:{' '}
                      <select
                        value={user.role}
                        className='border-none py-0'
                        onChange={e => {
                          set_users(users => {
                            users.find(
                              u => u.username === user.username
                            )!.role = e.target.value as UserModel['role'];

                            return [...users];
                          });
                        }}
                      >
                        <option value='MASTER'>Master</option>
                        <option value='LEADER'>Leader</option>
                        <option value='USER'>User</option>
                      </select>
                    </div>
                  </div>

                  <div className='flex space-x-2'>
                    <div
                      className='cursor-pointer active:opacity-50 bg-teal-500 rounded-full p-2'
                      onClick={() => {
                        submit_change(user);
                      }}
                    >
                      <CheckIcon className='h-5 w-5 stroke-[3] text-teal-50' />
                    </div>

                    <div
                      className='cursor-pointer active:opacity-50 bg-teal-500 rounded-full p-2'
                      onClick={() => {
                        if (!window.confirm(`Reset password user ini?`)) return;
                        reset_password(user);
                      }}
                    >
                      <WrenchIcon className='h-5 w-5 stroke-[2] text-teal-50' />
                    </div>
                  </div>
                </div>
              ))
            : 'No users...'}

          <div className='bg-white px-5 py-3 rounded shadow-md hover:shadow-lg transition-shadow flex items-center space-x-4'>
            <div className='space-y-1'>
              <DebounceInput
                type='text'
                className='font-bold text-xl p-0 border-none'
                value={new_username}
                onChange={e => {
                  set_new_username(e.target.value);
                }}
                debounceTimeout={1000}
              />

              <div>
                Role:{' '}
                <select
                  value={new_role}
                  className='border-none py-0'
                  onChange={e => {
                    set_new_role(e.target.value);
                  }}
                >
                  <option value='MASTER'>Master</option>
                  <option value='LEADER'>Leader</option>
                  <option value='USER'>User</option>
                </select>
              </div>
            </div>

            <div className='flex space-x-2'>
              <div
                className='cursor-pointer active:opacity-50 bg-teal-500 rounded-full p-2'
                onClick={create_new_user}
              >
                <CheckIcon className='h-5 w-5 stroke-[3] text-teal-50' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
