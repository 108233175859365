import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { LeafIcon } from '../../../Assets';
import Select from '../../../Components/Select';
import { errorMessage } from '../../../Helper';
import { WebsiteName } from '../../../constant';
import FollowUpNewMemberModel from '../../../types/follow-up-new-member';
import DateRangePickerNewMember from './DateRangePickerNewMember';
import { Range } from 'react-date-range';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import { CloudArrowUpIcon } from '@heroicons/react/20/solid';
import { Helmet } from 'react-helmet';

const page_size = 20;

const NoData = () => (
  <tr className='opacity-50 w-full text-center py-20 h-full'>
    <td colSpan={99} className='py-20'>
      <div className='w-24 m-auto'>
        <img src={LeafIcon.default} alt='No statements...' className='w-full' />
      </div>

      <div>Tidak ada transaksi...</div>
    </td>
  </tr>
);

const FollowUpNewMember = () => {
  const [follow_up, set_follow_up] = useState<FollowUpNewMemberModel[]>([]);
  const [csv_data, set_csv_data] = useState<FollowUpNewMemberModel[]>([]);
  const [page, set_page] = useState(1);
  const [total_data, set_total_data] = useState(0);
  const [total_page, set_total_page] = useState(0);
  const [agent_name, set_agent_name] = useState('');
  const [exporting, set_exporting] = useState(false);

  const [selected_web, set_selected_web] = useState<WebsiteName>(
    Object.values(WebsiteName)[0]
  );

  const [selected_referral, set_selected_referral] = useState('');

  //! Date Range Picker State and Trigger
  const [start_date, set_start_date] = useState<DateTime>(
    DateTime.now().minus({ day: 1 })
  );

  const [end_date, set_end_date] = useState<DateTime>(
    DateTime.now().minus({ day: 1 })
  );

  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: DateTime.now().minus({ day: 1 }).toJSDate(),
    endDate: DateTime.now().minus({ day: 1 }).toJSDate(),
    key: 'selection',
  });

  const [display_date_range_picker, set_display_date_range_picker] =
    useState<boolean>(false);

  const date_range_picker_ref = useRef<HTMLDivElement>(null);

  const toggleDateRangePicker = () => {
    if (!display_date_range_picker) {
      set_display_date_range_picker(true);
    } else {
      date_range_picker_ref.current?.classList.replace(
        'fade-in-top',
        'fade-out-top'
      );
      if (selectionRange.startDate)
        set_start_date(DateTime.fromJSDate(selectionRange.startDate));
      if (selectionRange.endDate)
        set_end_date(DateTime.fromJSDate(selectionRange.endDate));

      setTimeout(() => {
        set_display_date_range_picker(false);
      }, 300);
    }
  };

  //! EXPORTING TO CSV

  const convertToCSV = (data: any[]) => {
    // Get the keys from the first item in the data array
    const keys = Object.keys(data[0]);

    // Remove the "id" key from the keys array
    const filteredKeys = keys.filter(key => key !== 'id');

    // Create the header using the filtered keys
    const header = filteredKeys.join(',');

    // Create rows by mapping over the data and excluding the "id" key
    const rows = data.map(item => {
      const row = filteredKeys.map(key => item[key]);
      return row.join(',');
    });

    return [header, ...rows].join('\n');
  };

  const exportCSV = () => {
    try {
      set_exporting(true);
      const csvData = convertToCSV(csv_data);
      // Create a data URI for the CSV data
      const csvDataURI = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csvData
      )}`;

      // Create a temporary <a> element
      const link = document.createElement('a');
      link.href = csvDataURI;
      link.download = 'data.csv';

      // Simulate a click event on the link to trigger the file download
      link.click();
      alert('Export Data CSV Berhasil!');
      set_exporting(false);
    } catch (error) {
      alert(`Error converting to CSV:${error}`);
      return '';
    } finally {
      set_exporting(false);
    }
  };

  //! Retrieve Data
  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      try {
        const response = await fetch(
          `/api/player/follow-up-non-deposit?page=${page}&web=${selected_web}&referral=${selected_referral}&start_date=${start_date.toFormat(
            'yyyy-MM-dd'
          )}&end_date=${end_date.toFormat('yyyy-MM-dd')}`,
          { signal: controller.signal }
        );

        const res: {
          message: string;
          data: {
            player: FollowUpNewMemberModel[];
            allDataCSV: FollowUpNewMemberModel[];
            total: { data: number; page: number };
          };
        } = await response.json();

        console.log(res);
        if (!response.ok) throw new Error(errorMessage(res) || res.message);
        set_follow_up(res.data.player);
        set_csv_data(res.data.allDataCSV);
        set_total_data(res.data.total.data);
        set_total_page(res.data.total.page);
      } catch (error: any) {
        if (error.name === 'AbortError') return;
        alert(error.message);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [page, selected_web, start_date, end_date, selected_referral]);

  //! Page

  useEffect(() => {
    set_page(1);
  }, [selected_referral, start_date, end_date, selected_web]);

  return (
    <>
      <Helmet>
        <title>Follow Up New Member - Growth Tracker</title>
      </Helmet>

      {display_date_range_picker && (
        <div
          className='fixed left-0 top-0 right-0 bottom-0 z-0'
          onClick={toggleDateRangePicker}
        />
      )}
      <div className='p-5'>
        <div className='flex justify-between '>
          <h1 className='text-3xl font-bold'>New Member No Deposit</h1>

          <div className='flex space-x-2'>
            <Select
              displayValue={selected_web || 'ALL'}
              nullOptions={false}
              onChange={set_selected_web}
              options={Object.values(WebsiteName)}
              value={selected_web}
            />

            <DebounceInput
              debounceTimeout={500}
              type='text'
              className='shadow-sm focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded-md'
              value={selected_referral}
              placeholder='By Referral ...'
              onChange={e => {
                set_selected_referral(e.target.value);
              }}
            />

            <input
              type='text'
              className='shadow-sm focus:ring-teal-500 focus:border-teal-500 block sm:text-sm border-gray-300 rounded-md'
              value={agent_name}
              placeholder='Agent Name'
              onChange={e => {
                set_agent_name(e.target.value);
              }}
            />
            <DateRangePickerNewMember
              toggleDateRangePicker={toggleDateRangePicker}
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              displayDateRangePicker={display_date_range_picker}
              dateRangePickerRef={date_range_picker_ref}
            />
            <button
              className='inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer active:opacity-90 bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 text-white'
              onClick={exportCSV}
            >
              {exporting ? (
                <LoadingIndicator colorScheme={'light'} />
              ) : (
                <>
                  <CloudArrowUpIcon className='-ml-1 mr-2 h-5 w-5' /> Export ke
                  CSV
                </>
              )}
            </button>
          </div>
        </div>

        <div className='overflow-auto shadow ring-1 ring-black ring-opacity-5 rounded-md h-full overflow-y-scroll scrollbar-hide mt-4'>
          <table className='min-w-full divide-y divide-gray-300 shadow text-sm'>
            <thead className='bg-gray-50'>
              <tr className='font-semibold text-gray-900'>
                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6 text-left'>
                  Player ID
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6'>
                  Website
                </th>

                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6'>
                  Referral
                </th>
                <th className='whitespace-nowrap px-2 py-3.5 first-of-type:pl-6 last-of-type:pr-6 text-left'>
                  No.HP
                </th>
              </tr>
            </thead>

            <tbody className='divide-y  divide-gray-200 bg-white'>
              {follow_up?.length ? (
                follow_up.map(player => (
                  <tr key={player.playerId} className='text-gray-500'>
                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6'>
                      {player.playerId}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 text-center'>
                      {player.website}
                    </td>

                    <td className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 text-center'>
                      {player.referral}
                    </td>

                    <td
                      className='whitespace-nowrap px-2 py-2 first-of-type:pl-6 last-of-type:pr-6 cursor-pointer hover:text-teal-500'
                      onClick={() => {
                        window.open(
                          `https://api.whatsapp.com/send/?phone=62${player.phone.slice(
                            1
                          )}&text=Halo bos ${
                            player.namaRekening
                          }! Perkenalkan saya${
                            agent_name ? ' ' + agent_name : ''
                          } dari ${
                            player.website
                          }. Terima kasih ya bos ku sudah mendaftar di web kami. Saya hanya ingin mengkonfirmasi saja, apakah ada masalah terkait proses deposit atau permainannya bosku?&type=phone_number&app_absent=0`,
                          '_blank'
                        );
                      }}
                    >
                      {player.phone}
                    </td>
                  </tr>
                ))
              ) : (
                <NoData />
              )}
            </tbody>
          </table>

          <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
            <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
              <div>
                <p className='text-sm text-gray-700 mr-10'>
                  <span className='font-medium'>
                    {total_data > 0 ? page * page_size - (page_size - 1) : 0}
                  </span>{' '}
                  -{' '}
                  <span className='font-medium'>
                    {page * page_size > total_data
                      ? total_data
                      : page * page_size}
                  </span>{' '}
                  dari <span className='font-medium'>{total_data}</span>
                </p>
              </div>
            </div>

            <div>
              <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
                <button
                  className='relative inline-flex justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => set_page(1)}
                >
                  <ChevronDoubleLeftIcon className='h-5 w-5' />
                </button>

                <button
                  className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => {
                    if (page === 1) return;
                    set_page(page => page - 1);
                  }}
                >
                  <ChevronLeftIcon className='h-5 w-5' />
                </button>

                <button
                  className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => {
                    if (page === total_page) return;
                    set_page(page => page + 1);
                  }}
                >
                  <ChevronRightIcon className='h-5 w-5' />
                </button>

                <button
                  className='relative inline-flex justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                  onClick={() => set_page(total_page)}
                >
                  <ChevronDoubleRightIcon className='h-5 w-5' />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowUpNewMember;
