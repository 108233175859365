import axios from 'axios'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { Logo } from '../Assets'
import { nav_path } from '../constant'
import { errorMessage } from '../Helper'

const Login = () => {
  const navigate = useNavigate()

  const [username, set_username] = useState('')
  const [password, set_password] = useState('')

  const submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      if (!username) throw new Error('Username kosong...')
      if (!password) throw new Error('Password kosong...')
      await axios.post('/api/user/login', { username, password })
      navigate(nav_path.dashboard.index)
    } catch (error: any) {
      alert(
        errorMessage(error.response?.data || error) ||
          `Terjadi kesalahan ketika login...`
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Login - Growth Tracker</title>
      </Helmet>

      <div className='bg-gray-50 min-h-screen'>
        <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-md'>
            <img
              className='mx-auto h-12 w-auto'
              src={Logo.default}
              alt='AutoDeposit'
            />
            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
              Growth Tracker
            </h2>
          </div>

          <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
              <form className='space-y-6' onSubmit={submitLogin}>
                <div>
                  <label
                    htmlFor='username'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Username
                  </label>

                  <div className='mt-1'>
                    <input
                      id='username'
                      name='username'
                      type='username'
                      autoComplete='username'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm'
                      onChange={e => set_username(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Password
                  </label>

                  <div className='mt-1'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm'
                      onChange={e => set_password(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type='submit'
                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
