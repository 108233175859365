import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { DebounceInput } from 'react-debounce-input';
import { useNavigate } from 'react-router-dom';
import { LeafIcon } from '../../../Assets';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  classNames,
  errorMessage,
  get_trendlines,
  thousandSeparator,
} from '../../../Helper';
import { nav_path } from '../../../constant';
import PlayerModel from '../../../types/player';
import { TransactionModel } from '../../../types/transaction';

const NoDataPlayer = () => (
  <div className='opacity-50 w-auto text-center px-10 py-10 h-full'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No statements...' className='w-full' />
    </div>

    <div>Player Not Found...</div>
  </div>
);

const FindPlayer = () => {
  const navigate = useNavigate();

  const [list_nama_rekening, set_list_nama_rekening] = useState([]);
  const [list_no_rekening, set_list_no_rekening] = useState([]);

  const [player_id, set_player_id] = useState('');
  const [nomor_rek, set_nomor_rek] = useState('');
  const [nama_rek, set_nama_rek] = useState('');
  const [players, set_players] = useState<PlayerModel[]>([]);
  const [selected_player, set_selected_player] = useState<PlayerModel>();
  const [updating_player, set_updating_player] = useState<string>('');
  const [transactions, set_transactions] = useState<
    { _id: string; transactions: TransactionModel[] }[]
  >([]);

  useEffect(() => {
    const get_user = async () => {
      try {
        const uri = `/api/player/get-by-data?id=${player_id}&norekening=${nomor_rek}&namarekening=${nama_rek.toUpperCase()}`;
        const method = `GET`;
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(uri, { method, headers });
        const res = await response.json();
        if (response.status === 401) return navigate(nav_path.login);
        if (!response.ok) throw new Error(res.message);
        set_players(res.data);

        const namaRekenings = res.data.map(
          (player: any) => player.namaRekening
        );
        const noRekenings = res.data.map((player: any) => player.nomorRekening);
        set_list_nama_rekening(namaRekenings);
        set_list_no_rekening(noRekenings);
      } catch (error: any) {
        alert(error.message);
      }
    };

    get_user();
  }, [nomor_rek, nama_rek, player_id, navigate]);

  const datasets = useMemo(() => {
    if (!transactions || !transactions.length) return [];
    const datasets = [];

    const deposit_data = transactions.map(trx =>
      trx.transactions
        .filter(trx => trx.info === 'Deposit')
        .reduce((acc, curr) => acc + curr.amount, 0)
    );

    datasets.push({
      label: 'Deposit',
      borderColor: '#355BF5',
      backgroundColor: '#355BF5',
      data: deposit_data,
    });

    datasets.push({
      label: 'Trendline Deposit',
      data: get_trendlines(deposit_data),
      borderColor: '#355BF540',
      backgroundColor: '#355BF540',
    });

    const withdraw_data = transactions.map(trx =>
      trx.transactions
        .filter(trx => trx.info === 'Withdraw')
        .reduce((acc, curr) => acc + curr.amount, 0)
    );

    datasets.push({
      label: 'Withdraw',
      borderColor: '#F47174',
      backgroundColor: '#F47174',
      data: withdraw_data,
    });

    datasets.push({
      label: 'Trendline Withdraw',
      data: get_trendlines(withdraw_data),
      borderColor: '#F4717440',
      backgroundColor: '#F4717440',
    });

    return datasets;
  }, [transactions]);

  useEffect(() => {
    if (!selected_player) return;

    const get_transactions = async () => {
      const response = await fetch(
        `/api/transaction/by-player-per-dates/${selected_player.id}`
      );

      const res = await response.json();
      set_transactions(res.data);
    };

    get_transactions();
  }, [selected_player]);

  const update_player = async (player_id: string) => {
    try {
      const response = await fetch(`/api/player/update-data/${player_id}`, {
        method: 'PATCH',
      });

      const res = await response.json();
      if (!response.ok) throw new Error(errorMessage(res) || res.message);
      alert(res.message);
      const updated_player = res.data;

      set_players(players => {
        const index = players.findIndex(
          player => player.id === updated_player.id
        );

        if (index === -1) return players;
        players[index] = updated_player;
        return [...players];
      });
    } catch (error: any) {
      alert(error.message);
    } finally {
      set_updating_player('');
    }
  };

  const renderDatalistNamaRekeningOptions = () => {
    return list_nama_rekening.map(nama => (
      <option key={nama} value={nama}>
        {nama}
      </option>
    ));
  };

  const renderDatalistNoRekeningOptions = () => {
    return list_no_rekening.map(no => (
      <option key={no} value={no}>
        {no}
      </option>
    ));
  };

  const lifetime_value = useMemo(() => {
    return (
      players.reduce((acc, { depositTotal }) => acc + depositTotal, 0) -
      players.reduce((acc, { withdraw_total }) => acc + withdraw_total, 0)
    );
  }, [players]);

  return (
    <div className='p-4 overflow-y-auto h-screen'>
      <div className='flex flex-auto justify-between items-center w-full '>
        <h1 className='text-3xl font-bold'>Find Player</h1>
        <div className='flex space-x-2'>
          <DebounceInput
            debounceTimeout={500}
            type='text'
            placeholder='Cari No Rekening'
            list='listNo'
            onChange={e => set_nomor_rek(e.target.value)}
            className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2 w-48'
          />
          <datalist id='listNo'>{renderDatalistNoRekeningOptions()}</datalist>
          <DebounceInput
            debounceTimeout={500}
            type='text'
            list='listNama'
            placeholder='Cari Nama Rekening'
            onChange={e => set_nama_rek(e.target.value)}
            className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2 w-48'
          />

          <datalist id='listNama'>
            {renderDatalistNamaRekeningOptions()}
          </datalist>
          <DebounceInput
            debounceTimeout={500}
            type='text'
            placeholder='Cari ID Player'
            onChange={e => set_player_id(e.target.value)}
            className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
          />
        </div>
      </div>

      <div className='mt-4 rounded-md text-sm h-auto overflow-auto shadow bg-white'>
        <table className='border-none w-full'>
          <thead className='text-white whitespace-nowrap'>
            <tr>
              <th className='p-3 bg-teal-600'>Join Date</th>
              <th className='p-3 bg-teal-600'>Info Rekening</th>
              <th className='p-3 bg-teal-600'>Nama</th>
              <th className='p-3 bg-teal-600'>Username</th>
              <th className='p-3 bg-teal-600'>Tot. DP</th>
              <th className='p-3 bg-teal-600'>Tot. WD</th>
              <th className='p-3 bg-teal-600'>LTV</th>
              <th className='p-3 bg-teal-600'>DP. Count </th>
              <th className='p-3 bg-teal-600'>Avg. DP</th>
              <th className='p-3 bg-teal-600'>Break </th>
              <th className='p-3 bg-teal-600'>Break Avg.</th>
              <th className='p-3 bg-teal-600'>Ref</th>
              <th className='p-3 bg-teal-600'>Web</th>
              <th className='p-3 bg-teal-600'></th>
            </tr>
          </thead>

          <tbody className='text-gray-500 text-center h-full whitespace-nowrap divide-y divide-gray-200'>
            {players.length ? (
              players.map((player: PlayerModel) => {
                const lifetime_value =
                  player.depositTotal - player.withdraw_total;

                return (
                  <tr
                    key={player.playerId + player.website}
                    className={classNames(
                      'hover:bg-teal-50 cursor-pointer active:opacity-75',
                      selected_player?.id === player.id ? 'bg-teal-100' : ''
                    )}
                    onClick={async () => {
                      set_selected_player(player);
                    }}
                  >
                    <td className='p-3'>
                      {player.joinDate
                        ? `${DateTime.fromISO(
                            player.joinDate.toString()
                          ).toFormat('dd MMMM yyyy')} - ${DateTime.fromISO(
                            player.joinDate.toString()
                          ).toRelative()}`
                        : ''}
                    </td>

                    <td className='p-3'>
                      {player.namaBank} - {player.nomorRekening}
                    </td>

                    <td className='p-3'>{player.namaRekening}</td>
                    <td className='p-3'>{player.playerId}</td>

                    <td className='p-3'>
                      {thousandSeparator({ value: player.depositTotal })}
                    </td>

                    <td className='p-3'>
                      {thousandSeparator({ value: player.withdraw_total })}
                    </td>

                    <td className='p-3'>
                      {lifetime_value < 0 ? '-' : ''}
                      {thousandSeparator({ value: Math.abs(lifetime_value) })}
                    </td>

                    <td className='p-3'>
                      {thousandSeparator({ value: player.depositCount })}
                    </td>

                    <td className='p-3'>
                      {thousandSeparator({
                        value: Math.round(
                          player.depositTotal / player.depositCount
                        ),
                      })}
                    </td>

                    <td className='p-3'>
                      {player.last_deposit
                        ? Math.abs(
                            Number(
                              DateTime.fromISO(player.last_deposit?.toString())
                                .diffNow('day')
                                .as('day')
                                .toFixed(2)
                            )
                          )
                        : ''}
                    </td>

                    <td className='p-3'>{player.break_average}</td>
                    <td className='p-3'>{player.referral}</td>
                    <td className='p-3'>{player.website}</td>

                    <td className='py-1 px-3 pr-6 hover:text-teal-500 transition-colors'>
                      <button
                        className='bg-teal-500 text-teal-50 p-2 rounded active:opacity-75 w-20 flex items-center justify-center'
                        onClick={e => {
                          e.stopPropagation();
                          if (updating_player) return;
                          set_updating_player(player.id);
                          update_player(player.id);
                        }}
                      >
                        {updating_player === player.id ? (
                          <LoadingIndicator colorScheme='light' />
                        ) : (
                          'Update'
                        )}
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoDataPlayer />
                </td>
              </tr>
            )}
          </tbody>

          <tfoot className='text-white'>
            <tr className='text-center'>
              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>

              <td className='p-3 bg-teal-600'>
                {thousandSeparator({
                  value: players.reduce(
                    (acc, { depositTotal }) => acc + depositTotal,
                    0
                  ),
                })}
              </td>

              <td className='p-3 bg-teal-600'>
                {thousandSeparator({
                  value: players.reduce(
                    (acc, { withdraw_total }) => acc + withdraw_total,
                    0
                  ),
                })}
              </td>

              <td className='p-3 bg-teal-600'>
                {lifetime_value.toString().substring(0, 1) === '-' ? '-' : ''}
                {thousandSeparator({ value: Math.abs(lifetime_value) })}
              </td>

              <td className='p-3 bg-teal-600'>
                {thousandSeparator({
                  value: players.reduce(
                    (acc, { depositCount }) => acc + depositCount,
                    0
                  ),
                })}
              </td>

              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>
              <td className='p-3 bg-teal-600'></td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className='bg-white rounded-lg p-5 mt-5 shadow-lg'>
        <Line
          options={{
            responsive: true,
            font: { family: 'jakarta' },
            layout: { autoPadding: true },
            spanGaps: false,
            elements: { line: { tension: 0.4 } },

            plugins: {
              legend: { position: 'top' as const },

              title: {
                display: true,
                text: `${selected_player?.playerId}`,
              },
            },
          }}
          data={{
            labels: transactions.map(transaction => transaction._id),
            datasets,
          }}
        />
      </div>
    </div>
  );
};

export default FindPlayer;
