import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import { Range } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import Select from '../../../../Components/Select'
import StatisticsContext, {
  default_total_forms,
} from '../../../../Context/StatisticsContext'
import fetch_statistics_data from '../../../../Request/statistics-data'
import fetch_total_forms, {
  TotalFormsData,
} from '../../../../Request/total-form'
import { WebsiteName, nav_path } from '../../../../constant'
import ChartData from '../../../../types/chart-data'
import { Websites } from '../../../../types/website'
import DepositAverage from './Card/DepositAveragePerDay'
import DepositTotal from './Card/DepositTotal'
import FormAverage from './Card/FormCountAverage'
import FormTotal from './Card/FormCountTotal'
import FormValue from './Card/DepositAveragePerForm'
import GrowthPerDay from './Card/GrowthPerDay'
import PlayerTopDeposit from './Card/PlayerTopDeposit'
import PlayerTopWithdraw from './Card/PlayerTopWithdraw'
import DateRangePickerStatistics from './DateRangePickerStatistics'
import LineChart from './LineChart'
import LoadingIndicator from '../../../../Components/LoadingIndicator'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

const Statistics: React.FC = () => {
  const navigate = useNavigate()

  const [selected_web, setSelectedWeb] = useState<Websites[number] | ''>('')
  const [displayDateRangePicker, setDisplayDateRangePicker] =
    useState<boolean>(false)
  const [start_date, set_start_date] = useState<DateTime>(
    DateTime.now().minus({ day: 1, month: 1 })
  )
  const [end_date, set_end_date] = useState<DateTime>(
    DateTime.now().minus({ day: 1 })
  )
  const [chart_data, set_chart_data] = useState<ChartData>({})
  const [comparison, set_comparison] = useState<ChartData>({})

  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: DateTime.now().minus({ day: 1, month: 1 }).toJSDate(),
    endDate: DateTime.now().minus({ day: 1 }).toJSDate(),
    key: 'selection',
  })

  const dateRangePickerRef = useRef<HTMLDivElement>(null)

  const toggleDateRangePicker = () => {
    if (!displayDateRangePicker) {
      setDisplayDateRangePicker(true)
    } else {
      dateRangePickerRef.current?.classList.replace(
        'fade-in-top',
        'fade-out-top'
      )
      if (selectionRange.startDate)
        set_start_date(DateTime.fromJSDate(selectionRange.startDate))
      if (selectionRange.endDate)
        set_end_date(DateTime.fromJSDate(selectionRange.endDate))

      setTimeout(() => {
        setDisplayDateRangePicker(false)
      }, 300)
    }
  }

  useEffect(() => {
    const abort_controller = new AbortController()

    const get_statistics_data = async () => {
      try {
        const { data } = await fetch_statistics_data({
          start_date,
          end_date,
          abort_controller,
        })

        set_chart_data(data.latest)
        set_comparison(data.comparison)
      } catch (error: any) {
        if (error.name === 'AbortError') return

        alert(
          error.message || `Terjadi kesalahan ketika mengambil data deposit...`
        )

        if (error.message.includes('belum login')) navigate(nav_path.login)
      }
    }

    trackPromise(get_statistics_data(), 'statistics-data')

    return () => {
      abort_controller.abort()
    }
  }, [start_date, end_date, navigate])

  const { promiseInProgress: getting_statistics_data } = usePromiseTracker({
    area: 'statistics-data',
  })

  const [total_forms_data, set_total_forms_data] =
    useState<TotalFormsData>(default_total_forms)

  useEffect(() => {
    const abort_controller = new AbortController()

    ;(async () => {
      try {
        const { data } = await fetch_total_forms({
          start_date,
          end_date,
          abort_controller,
        })
        set_total_forms_data(data)
      } catch (error: any) {
        if (error.message.includes('aborted')) return
        alert(
          error.message || `Terjadi kesalahan ketika mengambil data deposit...`
        )
        if (error.message.includes('belum login')) navigate(nav_path.login)
      }
    })()

    return () => {
      abort_controller.abort()
    }
  }, [start_date, end_date, navigate])

  return (
    <>
      <Helmet>
        <title>Statistics - Growth Tracker</title>
      </Helmet>

      {displayDateRangePicker && (
        <div
          className='fixed left-0 top-0 right-0 bottom-0 z-0'
          onClick={toggleDateRangePicker}
        />
      )}

      <div className='p-5'>
        <div className='flex justify-between items-center w-full'>
          <h1 className='text-3xl font-bold'>Statistics</h1>

          <div className='flex space-x-3'>
            <Select
              displayValue={selected_web?.toString() || 'ALL'}
              nullOptions={true}
              nullMessage={'ALL'}
              onChange={setSelectedWeb}
              options={Object.values(WebsiteName)}
              value={selected_web}
            />

            <DateRangePickerStatistics
              toggleDateRangePicker={toggleDateRangePicker}
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              displayDateRangePicker={displayDateRangePicker}
              dateRangePickerRef={dateRangePickerRef}
            />
          </div>
        </div>

        <div className='py-5 px-6 my-4 bg-white rounded-md shadow-lg'>
          <LineChart chartData={chart_data} showingChart={selected_web} />
        </div>

        <StatisticsContext.Provider
          value={{
            chart_data,
            start_date,
            end_date,
            comparison,
            total_forms_data,
            selected_web,
          }}
        >
          <div className='flex items-start flex-wrap'>
            <PlayerTopDeposit selectedWeb={selected_web} />
            <PlayerTopWithdraw selectedWeb={selected_web} />
            <GrowthPerDay />
            <DepositTotal />
            <DepositAverage />
            <FormValue />
            <FormTotal />
            <FormAverage />
          </div>
        </StatisticsContext.Provider>
      </div>

      {getting_statistics_data && (
        <div className='bg-teal-900 bg-opacity-75 fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center'>
          <div className='text-white'>
            <div className='text-center'>
              <LoadingIndicator colorScheme='light' />
            </div>
            Sabar ya. Datanya banyak...
          </div>
        </div>
      )}
    </>
  )
}

export default Statistics
