import { DateTime } from 'luxon'
import { errorMessage } from '../Helper'
import { Websites } from '../types/website'

interface FetchPlayerTopWithdrawParams {
  start_date: DateTime
  end_date: DateTime
  signal: AbortSignal
  website: Websites[number] | ''
}

const fetch_player_top_withdraw = async ({
  start_date,
  end_date,
  signal,
  website,
}: FetchPlayerTopWithdrawParams) => {
  const uri = `/api/transaction/top-withdraw?dateStart=${start_date.toFormat(
    'yyyy-MM-dd'
  )}&dateEnd=${end_date.toFormat('yyyy-MM-dd')}&website=${website}`

  const response = await fetch(uri, { signal })
  const res = await response.json()
  if (!response.ok) throw new Error(errorMessage(res) || res.message)
  return { data: res.data }
}

export default fetch_player_top_withdraw
