import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Logo } from '../../../Assets';
import { all_nav, master_nav, leader_nav, user } from '../../../constant';
import { MobileMenuOpenCtx } from '../context';
import Nav from '../../../types/nav';
import { errorMessage } from '../../../Helper';
import { Link } from 'react-router-dom';

const MobileMenu: React.FC = () => {
  const { mobileMenuOpen, setMobileMenuOpen } = useContext(MobileMenuOpenCtx);
  const [nav, set_nav] = useState<Nav[]>([]);

  useEffect(() => {
    set_nav(all_nav);
    const abort_controller = new AbortController();

    const get_current_user = async () => {
      const response = await fetch('/api/user/current-user', {
        signal: abort_controller.signal,
      });

      const res = await response.json();
      if (!response.ok) throw new Error(errorMessage(res) || res.message);
      if (res.data.role === 'MASTER') set_nav(nav => [...nav, ...master_nav]);
      if (res.data.role === 'LEADER') set_nav(leader_nav);
    };

    get_current_user();

    return () => {
      abort_controller.abort();
    };
  }, []);

  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-40 lg:hidden'
        onClose={setMobileMenuOpen!}
      >
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
        </Transition.Child>

        <div className='fixed inset-0 z-40 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-4'>
                  <button
                    type='button'
                    className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() =>
                      setMobileMenuOpen && setMobileMenuOpen(false)
                    }
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XMarkIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </Transition.Child>

              <div className='pt-5 pb-4'>
                <div className='flex flex-shrink-0 items-center px-4'>
                  <img
                    className='h-8 w-auto'
                    src={Logo.default}
                    alt='Your Company'
                  />
                </div>

                <nav aria-label='Sidebar' className='mt-5'>
                  <div className='space-y-1 px-2'>
                    {nav.map(item => (
                      <Link
                        to={item.href}
                        key={item.name}
                        className='group flex items-center rounded-md p-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      >
                        <item.icon
                          className='mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />

                        {item.name}
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>

              <div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
                <div className='group block flex-shrink-0'>
                  <div className='flex items-center'>
                    <div>
                      <img
                        className='inline-block h-10 w-10 rounded-full'
                        src={user.imageUrl}
                        alt=''
                      />
                    </div>

                    <div className='ml-3'>
                      <p className='text-base font-medium text-gray-700 group-hover:text-gray-900'>
                        {user.name}
                      </p>
                      <p className='text-sm font-medium text-gray-500 group-hover:text-gray-700'>
                        Account Settings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>

          <div className='w-14 flex-shrink-0' aria-hidden='true'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileMenu;
