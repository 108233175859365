import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { LogoLight } from '../../../Assets';
import { classNames, errorMessage } from '../../../Helper';
import {
  all_nav,
  master_nav,
  leader_nav,
  nav_path,
  user,
} from '../../../constant/';
import Nav from '../../../types/nav';

const Sidebar = () => {
  const navigate = useNavigate();
  const [show_menu, set_show_menu] = useState(false);
  const [change_password, set_change_password] = useState(false);
  const [new_password, set_new_password] = useState('');
  const [nav, set_nav] = useState<Nav[]>([]);

  const logout = async () => {
    await fetch('/api/user/logout', { method: 'POST' });
    navigate(nav_path.login);
  };

  const submit_change_password = async () => {
    const response = await fetch(`/api/user/change-password`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: new_password }),
    });

    const res = await response.json();
    alert(errorMessage(res) || res.message);
    navigate(nav_path.login);
  };

  useEffect(() => {
    set_nav(all_nav);
    const abort_controller = new AbortController();

    const get_current_user = async () => {
      const response = await fetch('/api/user/current-user', {
        signal: abort_controller.signal,
      });

      const res = await response.json();
      if (!response.ok) throw new Error(errorMessage(res) || res.message);
      if (res.data.role === 'MASTER') set_nav(nav => [...nav, ...master_nav]);
      if (res.data.role === 'LEADER') set_nav(leader_nav);
    };

    get_current_user();

    return () => {
      abort_controller.abort();
    };
  }, []);

  return (
    <>
      <div className='hidden lg:flex lg:flex-shrink-0 z-30'>
        <div className='flex w-20 flex-col'>
          <div className='flex min-h-0 flex-1 flex-col overflow-y-auto bg-teal-600'>
            <div className='flex-1'>
              <div className='flex items-center justify-center bg-teal-700 py-4'>
                <img
                  className='h-8 w-auto'
                  src={LogoLight.default}
                  alt='Hasil Hoki'
                />
              </div>

              <nav
                aria-label='Sidebar'
                className='flex flex-col items-center space-y-3 py-6'
              >
                {nav.map(item => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'bg-teal-800 text-white'
                          : 'text-teal-100 hover:bg-teal-700 hover:text-teal-200',
                        'flex items-center rounded-lg p-4 cursor-pointer transition-colors'
                      )
                    }
                  >
                    <item.icon className='h-6 w-6' />
                  </NavLink>
                ))}
              </nav>
            </div>

            <div
              className='flex pb-5 cursor-pointer'
              onClick={() => {
                set_show_menu(!show_menu);
              }}
            >
              <img
                className='mx-auto block h-10 w-10 rounded-full text-white'
                src={user.imageUrl}
                alt='User'
              />
            </div>

            {show_menu && (
              <div className='absolute bg-white bottom-16 left-16 shadow rounded overflow-hidden z-10'>
                <div
                  className='px-4 py-2 cursor-pointer hover:bg-teal-50 active:opacity-75'
                  onClick={() => {
                    set_change_password(true);
                  }}
                >
                  <div>Change Password</div>
                </div>

                <div
                  className='px-4 py-2 cursor-pointer hover:bg-teal-50 active:opacity-75'
                  onClick={logout}
                >
                  <div>Logout</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {change_password && (
        <div
          className='bg-black bg-opacity-25 fixed h-screen w-screen z-20 flex items-center justify-center'
          onClick={() => {
            set_change_password(false);
          }}
        >
          <div
            className='bg-white rounded-md p-5 text-sm w-1/4'
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div>
              <input
                type='password'
                placeholder='New password...'
                className='rounded text-sm w-full text-center'
                onChange={e => {
                  set_new_password(e.target.value);
                }}
              />
            </div>

            <button
              className='bg-teal-500 text-teal-50 w-full p-2 rounded mt-2'
              onClick={submit_change_password}
            >
              Save
            </button>

            <button
              className='border-teal-500 text-teal-500 border rounded mt-2 w-full p-2'
              onClick={() => {
                set_change_password(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
