import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { errorMessage } from '../Helper'
import { nav_path } from '../constant'

const useTokenValidation = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const is_token_valid = async () => {
      try {
        const response = await fetch('/api/user/validate-token')
        const res = await response.json()

        if (response.status === 401 || !res.tokenValid) {
          throw new Error(errorMessage(res) || res.message)
        }
      } catch (error: any) {
        alert(error.message)
        navigate(nav_path.login)
      }
    }

    is_token_valid()
  }, [navigate])
}

export default useTokenValidation
