import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './Pages/Dashboard'
import CrossPlayer from './Pages/Dashboard/Outlet/CrossPlayer'
import FindPlayer from './Pages/Dashboard/Outlet/FindPlayer'
import FollowUp from './Pages/Dashboard/Outlet/FollowUp'
import Pemain from './Pages/Dashboard/Outlet/Player'
import Statistics from './Pages/Dashboard/Outlet/Statistics/'
import Transaction from './Pages/Dashboard/Outlet/Transaction'
import User from './Pages/Dashboard/Outlet/User'
import Loading from './Pages/Loading'
import Login from './Pages/Login'
import { nav_path } from './constant'
import CrossIP from './Pages/Dashboard/Outlet/CrossIP'
import FollowUpNewMember from './Pages/Dashboard/Outlet/FollowUpNewMember'
import FollowUpNoDeposit from './Pages/Dashboard/Outlet/FollowUpNoDeposit'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Loading />} />
        <Route path={nav_path.login} element={<Login />} />

        <Route path={nav_path.dashboard.index} element={<Dashboard />}>
          <Route
            path={nav_path.dashboard.statistics}
            element={<Statistics />}
          />

          <Route
            path={nav_path.dashboard.transaction}
            element={<Transaction />}
          />

          <Route path={nav_path.dashboard.pemain} element={<Pemain />} />

          <Route
            path={nav_path.dashboard.cross_player}
            element={<CrossPlayer />}
          />

          <Route path={nav_path.dashboard.cross_ip} element={<CrossIP />} />

          <Route path={nav_path.dashboard.follow_up} element={<FollowUp />} />
          <Route path={nav_path.dashboard.follow_up_no_deposit} element={<FollowUpNoDeposit />} />
          <Route path={nav_path.dashboard.follow_up_new} element={<FollowUpNewMember />} />
          <Route path={nav_path.dashboard.user} element={<User />} />

          <Route
            path={nav_path.dashboard.find_player}
            element={<FindPlayer />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
