enum WebsiteName {
  PUB = 'PUB',
  SPACE = 'SPACE',
  EDM = 'EDM',
  BAR = 'BAR',
  GAS = 'GAS',
  GOOD = 'GOOD',
  GM = 'GM',
  WINA = 'WINA',
  WON = 'WON',
  NOMOR = 'NOMOR',
  ESMI = 'ESMI'
}

export default WebsiteName
