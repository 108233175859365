import { id } from 'date-fns/locale'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { DateRangePicker, Range } from 'react-date-range'
import { classNames } from '../../../Helper'

interface DateRangePickerPlayerProps {
  toggleDateRangePicker: () => void
  selectionRange: Range
  setSelectionRange: React.Dispatch<React.SetStateAction<Range>>
  displayDateRangePicker: boolean
  dateRangePickerRef: React.RefObject<HTMLDivElement>
}

const DateRangePickerNewMember: React.FC<DateRangePickerPlayerProps> = ({
  toggleDateRangePicker,
  selectionRange,
  setSelectionRange,
  displayDateRangePicker,
  dateRangePickerRef,
}) => {
  const startAndEndSameDay = useMemo(() => {
    if (selectionRange.startDate && selectionRange.endDate)
      return (
        selectionRange.startDate.toDateString() ===
        selectionRange.endDate.toDateString()
      )
  }, [selectionRange.startDate, selectionRange.endDate])

  return (
    <div className='relative z-20'>
      <button
        className={classNames(
          'inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer active:opacity-90 bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 text-white'
        )}
        onClick={toggleDateRangePicker}
      >
        {selectionRange.startDate &&
          DateTime.fromJSDate(selectionRange.startDate).toFormat(
            'dd MMMM yyyy'
          )}

        {selectionRange.endDate &&
          !startAndEndSameDay &&
          ` - ${DateTime.fromJSDate(selectionRange.endDate).toFormat(
            'dd MMMM yyyy'
          )}`}
      </button>

      {displayDateRangePicker && (
        <div
          className={classNames(
            'shadow rounded absolute overflow-hidden right-0 mt-2 fade-in-top'
          )}
          ref={dateRangePickerRef}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={ranges => {
              setSelectionRange(ranges.selection)
            }}
            rangeColors={['#0d9488']}
            dateDisplayFormat='dd MMMM yyyy'
            locale={id}
          />
        </div>
      )}
    </div>
  )
}

export default DateRangePickerNewMember
