interface Params {
  value: string | number
  placeholder?: string
  separator?: string
}
const thousandSeparator = ({ value, placeholder, separator }: Params) => {
  if (!placeholder) placeholder = '-'
  if (!separator) separator = '.'
  if (!value || isNaN(+value)) return placeholder

  const is_negative = +value < 0

  let result = ''
  const numbersArr = value.toString().split('').reverse()

  for (let i in numbersArr) {
    if (i !== '0' && +i % 3 === 0) result = separator + result
    result = numbersArr[i] + result
  }

  return `${is_negative ? '-' : ''}${result}`
}

export default thousandSeparator
