import { DateTime } from 'luxon'
import { errorMessage } from '../Helper'
import { WebsiteName } from '../constant'

interface FetchTotalFormsParams {
  start_date: DateTime
  end_date: DateTime
  abort_controller: AbortController
}

export interface TotalFormsData {
  latest: TotalFormsResponse[]
  comparison: TotalFormsResponse[]
}

export interface TotalFormsResponse {
  website: WebsiteName
  total_forms: number
  average: number
}

const fetch_total_forms = async ({
  start_date,
  end_date,
  abort_controller,
}: FetchTotalFormsParams) => {
  const uri = `/api/transaction/total-form?start_date=${start_date.toFormat(
    'dd-MM-yyyy'
  )}&end_date=${end_date.toFormat('dd-MM-yyyy')}`

  const response = await fetch(uri, { signal: abort_controller.signal })
  const res: { message: string; data: TotalFormsData } = await response.json()
  if (!response.ok) throw new Error(errorMessage(res) || res.message)
  return { data: res.data }
}

export default fetch_total_forms
