import { thousandSeparator } from 'ody-utils'
import { classNames } from '../../../../../Helper'
import { useContext } from 'react'
import StatisticsContext from '../../../../../Context/StatisticsContext'

const FormTotal = () => {
  const { total_forms_data: total_forms_res, selected_web } =
    useContext(StatisticsContext)

  return (
    <div className='bg-white rounded-md overflow-hidden shadow-md space-y-3 mb-5 mr-5'>
      <h2 className='font-bold tracking-widest text-center pt-4 px-10'>
        FORM COUNT TOTAL
      </h2>

      <table className='w-full text-sm'>
        <thead>
          <tr className='text-white'>
            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-left'>
              Website
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right'>
              Total
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right'>
              Growth
            </th>
          </tr>
        </thead>

        <tbody>
          {total_forms_res.latest
            .sort((a, b) => b.total_forms - a.total_forms)
            .map(({ website, total_forms }) => (
              <tr key={website} className='opacity-60'>
                <td
                  className={classNames(
                    'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6',
                    selected_web === website
                      ? 'font-bold bg-teal-50'
                      : 'bg-teal-100'
                  )}
                >
                  {website}
                </td>

                <td
                  className={classNames(
                    'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono justify-between',
                    selected_web === website
                      ? 'font-medium bg-teal-50'
                      : 'font-light bg-teal-100'
                  )}
                >
                  {thousandSeparator({
                    numbers: Math.round(Math.abs(total_forms)),
                  })}
                </td>

                <td
                  className={classNames(
                    'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono',
                    selected_web === website
                      ? 'font-medium bg-teal-50'
                      : 'font-light bg-teal-100'
                  )}
                >
                  {(
                    ((total_forms -
                      (total_forms_res.comparison.find(
                        w => w.website === website
                      )?.total_forms || 0)) /
                      (total_forms_res.comparison.find(
                        w => w.website === website
                      )?.total_forms || 0)) *
                    100
                  ).toFixed(2)}
                  %
                </td>
              </tr>
            ))}
        </tbody>

        <tfoot className='text-teal-50'>
          <tr>
            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-medium'>
              Total
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-mono text-right'>
              {thousandSeparator({
                numbers: Math.round(
                  Math.abs(
                    total_forms_res.latest.reduce(
                      (acc, { total_forms }) => acc + total_forms,
                      0
                    )
                  )
                ),
              })}
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-mono text-right'>
              {(
                ((total_forms_res.latest.reduce(
                  (acc, { total_forms }) => acc + total_forms,
                  0
                ) -
                  total_forms_res.comparison.reduce(
                    (acc, { total_forms }) => acc + total_forms,
                    0
                  )) /
                  total_forms_res.comparison.reduce(
                    (acc, { total_forms }) => acc + total_forms,
                    0
                  )) *
                100
              ).toFixed(2)}
              %
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default FormTotal
