import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { generate_dark_color, generate_light_color } from '../../../../Helper'
import { WebsiteName } from '../../../../constant'
import { Websites } from '../../../../types/website'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title
)

interface LineChartProps {
  chartData: any
  showingChart: Websites[number] | ''
}

const LineChart: React.FC<LineChartProps> = ({ chartData, showingChart }) => {
  const labels = Object.keys(chartData)
  const datasets = []

  datasets.push(
    ...Object.keys(WebsiteName)
      .filter(web => (showingChart ? web === showingChart.toString() : true))

      .map(website => ({
        label: website,
        data: labels.map(label => chartData[label][website]),
        borderColor: showingChart ? 'rgb(0, 0, 0)' : generate_dark_color(),
        backgroundColor: showingChart ? 'rgb(0, 0, 0)' : generate_light_color(),
      }))
  )

  if (showingChart) {
    const data = labels
      .map(label => chartData[label][showingChart])
      .filter(a => a)
    const n = data.length
    const sumXY = data.reduce((acc, val, i) => acc + val * (i + 1), 0)
    const sumX = data.reduce((acc, _val, i) => acc + (i + 1), 0)
    const sumY = data.reduce((acc, val) => acc + val, 0)
    const sumXSquare = data.reduce((acc, _val, i) => acc + (i + 1) ** 2, 0)
    const sumXSquared = sumX ** 2
    const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumXSquared)
    const offset = (sumY - slope * sumX) / n
    const y = (x: number) => slope * x + offset

    datasets.push({
      label: 'Trendline ' + showingChart,
      data: labels.map((_label, i) => y(i + 1)),
      borderColor: 'rgba(0, 0, 0, 0.15)',
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    })
  }

  return (
    <Line
      options={{
        responsive: true,
        font: { family: 'jakarta' },
        layout: { autoPadding: true },
        spanGaps: false,
        elements: { line: { tension: 0.4 } },

        plugins: {
          legend: { position: 'top' as const, display: !showingChart },

          title: {
            display: true,
            text: `Total Deposit ${showingChart || '/ Website'}`,
          },
        },
      }}
      data={{ labels, datasets }}
    />
  )
}

export default LineChart
