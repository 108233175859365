const generate_light_color = () => {
  let r, g, b

  do {
    r = Math.floor(Math.random() * 256)
    g = Math.floor(Math.random() * 256)
    b = Math.floor(Math.random() * 256)
  } while (r + g + b < 400)

  return `rgb(${r}, ${g}, ${b}`
}

export default generate_light_color
