import { DateTime } from 'luxon'
import { createContext } from 'react'
import { TotalFormsData } from '../Request/total-form'
import { WebsiteName } from '../constant'
import ChartData from '../types/chart-data'
import { Websites } from '../types/website'

export const default_total_forms: TotalFormsData = {
  latest: Object.values(WebsiteName).map(website => ({
    website,
    total_forms: 0,
    average: 0,
  })),

  comparison: Object.values(WebsiteName).map(website => ({
    website,
    total_forms: 0,
    average: 0,
  })),
}

const StatisticsContext = createContext<{
  chart_data: ChartData
  comparison: ChartData
  start_date: DateTime
  end_date: DateTime
  total_forms_data: TotalFormsData
  selected_web: Websites[number] | ''
}>({
  chart_data: {},
  comparison: {},
  start_date: DateTime.now(),
  end_date: DateTime.now(),
  total_forms_data: default_total_forms,
  selected_web: '',
})

export default StatisticsContext
