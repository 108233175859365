const nav_path = {
  dashboard: {
    index: '/dashboard',
    statistics: '/dashboard/statistics',
    transaction: '/dashboard/transaction',
    pemain: `/dashboard/pemain`,
    cross_player: `/dashboard/cross-player`,
    cross_ip: `/dashboard/cross-ip`,
    follow_up: `/dashboard/follow-up`,
    follow_up_new: `/dashboard/follow-up-new-member`,
    follow_up_no_deposit:`/dashboard/follow-up-no-deposit`,
    user: '/dashboard/user',
    find_player: '/dashboard/find-player',
  },

  login: '/login',
}

export default nav_path
