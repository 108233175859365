import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import StatisticsContext from '../../../../../Context/StatisticsContext'
import { classNames, thousandSeparator } from '../../../../../Helper'
import fetch_deposit_average from '../../../../../Request/form-average'
import { nav_path } from '../../../../../constant'
import { Websites } from '../../../../../types/website'

const FormValue = () => {
  const navigate = useNavigate()

  const [form_average, set_form_average] = useState<
    { _id: Websites; deposit_average: number }[]
  >([])

  const [comparison, set_comparison] = useState<
    { _id: Websites; deposit_average: number }[]
  >([])

  const { start_date, end_date, selected_web } = useContext(StatisticsContext)

  useEffect(() => {
    const abort_controller = new AbortController()

    ;(async () => {
      try {
        const { data } = await fetch_deposit_average({
          start_date,
          end_date,
          signal: abort_controller.signal,
        })

        set_form_average(data.latest)
        set_comparison(data.comparison)
      } catch (error: any) {
        if (error.message.includes('aborted')) return

        alert(
          error.message || `Terjadi kesalahan ketika mengambil data deposit...`
        )

        if (error.message.includes('belum login')) navigate(nav_path.login)
      }
    })()

    return () => {
      abort_controller.abort()
    }
  }, [start_date, end_date, navigate])

  return (
    <div className='bg-white rounded-md overflow-hidden shadow-md space-y-3 mb-5 mr-5'>
      <h2 className='font-bold tracking-widest mb-3 text-center pt-4 px-10'>
        DEPOSIT AVERAGE / FORM
      </h2>

      <table className='w-full text-sm'>
        <thead>
          <tr className='text-white'>
            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-left'>
              Website
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right'>
              Average
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right'>
              Growth
            </th>
          </tr>
        </thead>

        <tbody>
          {form_average.map((item, index) => (
            <tr key={`${item._id}-deposit-average`} className='opacity-60'>
              <td
                className={classNames(
                  'py-2 px-4 first-of-type:pl-6 last-of-type:pr-6',
                  selected_web === (item._id as unknown as Websites[number])
                    ? 'font-bold bg-teal-50'
                    : 'bg-teal-100'
                )}
              >
                {item._id}
              </td>

              <td
                className={classNames(
                  'py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono',
                  selected_web === (item._id as unknown as Websites[number])
                    ? 'font-medium bg-teal-50'
                    : 'font-light bg-teal-100'
                )}
              >
                {thousandSeparator({ value: Math.round(item.deposit_average) })}
              </td>

              <td
                className={classNames(
                  'py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono',
                  selected_web === (item._id as unknown as Websites[number])
                    ? 'font-medium bg-teal-50'
                    : 'font-light bg-teal-100'
                )}
              >
                {(
                  ((item.deposit_average -
                    (comparison.find(i => i._id === item._id)
                      ?.deposit_average || 0)) /
                    item.deposit_average) *
                  100
                ).toFixed(2)}
                %
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot className='text-teal-50'>
          <tr>
            <td className='py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 bg-teal-500 font-medium'>
              Average
            </td>

            <td className='py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 bg-teal-500 font-mono text-right'>
              {thousandSeparator({
                value: Math.round(
                  Math.abs(
                    form_average.reduce(
                      (a, { deposit_average }) => a + deposit_average,
                      0
                    ) / form_average.length
                  )
                ),
              })}
            </td>

            <td className='py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 bg-teal-500 font-mono text-right'>
              {(
                ((form_average.reduce(
                  (a, { deposit_average }) => a + deposit_average,
                  0
                ) /
                  form_average.length -
                  comparison.reduce(
                    (a, { deposit_average }) => a + deposit_average,
                    0
                  ) /
                    comparison.length) /
                  (comparison.reduce(
                    (a, { deposit_average }) => a + deposit_average,
                    0
                  ) /
                    comparison.length)) *
                100
              ).toFixed(2)}
              %
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default FormValue
