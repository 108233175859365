import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from '@heroicons/react/24/outline';
import { useContext, useMemo } from 'react';
import StatisticsContext from '../../../../../Context/StatisticsContext';
import {
  classNames,
  get_slope,
  thousandSeparator,
} from '../../../../../Helper';
import { WebsiteName } from '../../../../../constant';
import { Websites } from '../../../../../types/website';

const GrowthPerDay = () => {
  const { chart_data, comparison, selected_web } =
    useContext(StatisticsContext);

  const growth_slopes = useMemo(() => {
    const data = new Map<Websites[number], number[]>();

    for (const deposit of Object.values(chart_data)) {
      for (const website of Object.keys(deposit)) {
        data.set(website as Websites[number], [
          ...(data.get(website as Websites[number]) || []),
          deposit[website as Websites[number]],
        ]);
      }
    }

    const slopes = Object.fromEntries(
      Object.values(WebsiteName).map(website => [
        website,
        get_slope(data.get(website) || []),
      ])
    );

    const sorted_slopes = Object.entries(slopes).sort(([, a], [, b]) => b - a);
    return sorted_slopes;
  }, [chart_data]);

  const comparison_slopes = useMemo(() => {
    const data = new Map<Websites[number], number[]>();

    for (const deposit of Object.values(comparison || {})) {
      for (const website of Object.keys(deposit)) {
        data.set(website as Websites[number], [
          ...(data.get(website as Websites[number]) || []),
          deposit[website as Websites[number]],
        ]);
      }
    }

    const slopes = Object.fromEntries(
      Object.values(WebsiteName).map(website => [
        website,
        get_slope(data.get(website) || []),
      ])
    );

    return slopes;
  }, [comparison]);

  const growth_average = useMemo(() => {
    return (
      growth_slopes.reduce((acc, [, growth]) => acc + (growth || 0), 0) /
      growth_slopes.length
    );
  }, [growth_slopes]);

  const growth_average_movement = useMemo(() => {
    const comparison_growth =
      Object.entries(comparison_slopes).reduce(
        (acc, [, growth]) => acc + (growth || 0),
        0
      ) / Object.entries(comparison_slopes).length;

    return growth_average - comparison_growth;
  }, [growth_average, comparison_slopes]);

  return (
    <div className='bg-white rounded-md overflow-hidden shadow-md space-y-3 mb-5 mr-5'>
      <h2 className='font-bold tracking-widest mb-3 text-center pt-4 px-10'>
        GROWTH / DAY
      </h2>

      <table className='w-full text-sm'>
        <thead>
          <tr className='text-white'>
            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-left'>
              Website
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right'>
              Growth Value
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right'>
              Movement
            </th>
          </tr>
        </thead>

        <tbody>
          {growth_slopes.map(([website, growth]) => {
            const previous_growth =
              comparison_slopes[website as Websites[number]] || 0;

            return (
              <tr key={website} className='opacity-60'>
                <td
                  className={classNames(
                    'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6',
                    selected_web === website
                      ? 'font-bold bg-teal-50'
                      : 'bg-teal-100'
                  )}
                >
                  {website}
                </td>

                <td
                  className={classNames(
                    'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono flex justify-between',
                    selected_web === website
                      ? 'font-medium bg-teal-50'
                      : 'font-light bg-teal-100'
                  )}
                >
                  <div></div>

                  <div className='flex items-center space-x-1'>
                    <div>
                      {growth < 0 ? (
                        <ArrowSmallDownIcon
                          height={16}
                          width={16}
                          className='stroke-2'
                        />
                      ) : (
                        <ArrowSmallUpIcon
                          height={16}
                          width={16}
                          className='stroke-2'
                        />
                      )}
                    </div>

                    <div>
                      {thousandSeparator({
                        value: Math.round(Math.abs(growth)),
                      })}
                    </div>
                  </div>
                </td>

                <td
                  className={classNames(
                    'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono',
                    selected_web === website
                      ? 'font-medium bg-teal-50'
                      : 'font-light bg-teal-100'
                  )}
                >
                  <span className='mr-1'>
                    {growth - previous_growth < 0 ? '-' : '+'}
                  </span>

                  {thousandSeparator({
                    value: Math.round(Math.abs(growth - previous_growth)),
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>

        <tfoot className='text-teal-50'>
          <tr>
            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-medium'>
              Average
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-mono text-right'>
              <div className='flex justify-between'>
                <div></div>

                <div className='flex items-center space-x-1'>
                  <div>
                    {growth_average < 0 ? (
                      <ArrowSmallDownIcon
                        height={16}
                        width={16}
                        className='stroke-2'
                      />
                    ) : (
                      <ArrowSmallUpIcon
                        height={16}
                        width={16}
                        className='stroke-2'
                      />
                    )}
                  </div>

                  <div>
                    {thousandSeparator({
                      value: Math.abs(Math.round(growth_average)),
                    })}
                  </div>
                </div>
              </div>
            </td>

            <td className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 font-mono text-right'>
              <span className='mr-1'>
                {growth_average_movement < 0 ? '-' : '+'}
              </span>

              {thousandSeparator({
                value: Math.abs(Math.round(growth_average_movement)),
              })}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default GrowthPerDay;
