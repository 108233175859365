import {
  PhoneIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import Nav from '../types/nav';
import nav_path from './nav-path';

const leader_nav: Nav[] = [
  { name: 'Users', icon: UserGroupIcon, href: nav_path.dashboard.pemain },
  {
    name: 'Follow Up New Member',
    icon: PhoneIcon,
    href: nav_path.dashboard.follow_up_new,
  },
];

export default leader_nav;
