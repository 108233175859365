import axios from 'axios'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { nav_path } from '../constant'
import { errorMessage } from '../Helper'

const Loading: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      try {
        const uri = '/api/user/validate-token'
        const { data } = await axios.get<{ tokenValid: boolean }>(uri)
        if (data.tokenValid) navigate(nav_path.dashboard.statistics)
        else if (!data.tokenValid) navigate(nav_path.login)
      } catch (error: any) {
        alert(
          errorMessage(error.response?.data || error) ||
            `Terjadi kesalahan ketika mengecek token...`
        )
      }
    })()
  }, [navigate])

  return (
    <>
      <Helmet>
        <title>Loading - Growth Tracker</title>
      </Helmet>
      <div className='min-h-screen flex justify-center items-center'>
        Loading...
      </div>
      ;
    </>
  )
}

export default Loading
