import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

interface PaginationProps {
  page: number
  set_page: React.Dispatch<React.SetStateAction<number>>
  total_data: number
  total_page: number
}

const page_size = 10

const Pagination: React.FC<PaginationProps> = ({
  page,
  set_page,
  total_data,
  total_page,
}) => {
  return (
    <div className='bg-teal-500 text-teal-50 px-4 py-3 flex items-center justify-between border-t border-teal-900 sm:px-6 rounded-b'>
      <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
        <div>
          <p className='text-sm mr-10'>
            <span className='font-medium'>
              {total_data > 0 ? page * page_size - (page_size - 1) : 0}
            </span>{' '}
            -{' '}
            <span className='font-medium'>
              {page * page_size > total_data ? total_data : page * page_size}
            </span>{' '}
            dari&nbsp;<span className='font-medium'>{total_data}</span>
          </p>
        </div>
      </div>

      <div>
        <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
          <button
            className='relative inline-flex justify-center px-2 py-2 rounded-l-md border bg-teal-50 hover:bg-teal-100 text-sm font-medium text-teal-500 w-12'
            onClick={() => set_page(1)}
          >
            <ChevronDoubleLeftIcon className='h-5 w-5' />
          </button>

          <button
            className='relative inline-flex justify-center px-2 py-2 border bg-teal-50 hover:bg-teal-100 text-sm font-medium text-teal-500 w-12'
            onClick={() => {
              if (page === 1) return
              set_page(page => page - 1)
            }}
          >
            <ChevronLeftIcon className='h-5 w-5' />
          </button>

          <button
            className='relative inline-flex justify-center px-2 py-2 border bg-teal-50 hover:bg-teal-100 text-sm font-medium text-teal-500 w-12'
            onClick={() => {
              if (page === total_page) return
              set_page(page => page + 1)
            }}
          >
            <ChevronRightIcon className='h-5 w-5' />
          </button>

          <button
            className='relative inline-flex justify-center px-2 py-2 rounded-r-md border bg-teal-50 hover:bg-teal-100 text-sm font-medium text-teal-500 w-12'
            onClick={() => set_page(total_page)}
          >
            <ChevronDoubleRightIcon className='h-5 w-5' />
          </button>
        </nav>
      </div>
    </div>
  )
}

export default Pagination
