import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import StatisticsContext from '../../../../../Context/StatisticsContext'
import { classNames, thousandSeparator } from '../../../../../Helper'
import fetch_player_top_deposit from '../../../../../Request/player-top-deposit'
import { nav_path } from '../../../../../constant'
import { Websites } from '../../../../../types/website'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import LoadingIndicator from '../../../../../Components/LoadingIndicator'

interface PlayerTopDepositParams {
  selectedWeb: Websites[number] | ''
}

const PlayerTopDeposit: React.FC<PlayerTopDepositParams> = ({
  selectedWeb,
}) => {
  const navigate = useNavigate()
  const { start_date, end_date } = useContext(StatisticsContext)

  const [top_deposit, set_top_deposit] = useState<
    {
      deposit: number
      _id: { username: string; website: Websites[number] | '' }
    }[]
  >([])

  useEffect(() => {
    const abortController = new AbortController()

    const get_top_deposit = async () => {
      try {
        const { data } = await fetch_player_top_deposit({
          start_date,
          end_date,
          signal: abortController.signal,
          website: selectedWeb || '',
        })

        set_top_deposit(data)
      } catch (error: any) {
        if (error.name === 'AbortError') return

        alert(
          error.message || `Terjadi kesalahan ketika mengambil data deposit...`
        )

        if (error.message.includes('belum login')) navigate(nav_path.login)
      }
    }

    trackPromise(get_top_deposit(), 'player_top_deposit')

    return () => {
      abortController.abort()
    }
  }, [start_date, end_date, selectedWeb, navigate])

  const { promiseInProgress: getting_top_deposit } = usePromiseTracker({
    area: 'player_top_deposit',
  })

  return (
    <div className='bg-white rounded-md overflow-hidden shadow-md space-y-3 mb-5 mr-5'>
      <h2 className='font-bold tracking-widest mb-3 text-center pt-4 px-10'>
        PLAYER TOP DEPOSIT
      </h2>

      <table className='w-full text-sm'>
        <thead>
          <tr className='text-white'>
            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-left'>
              Username
            </th>

            <th
              className={classNames(
                'bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6',
                selectedWeb ? 'hidden' : ''
              )}
            >
              Web
            </th>

            <th className='bg-teal-500 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right'>
              Total Deposit
            </th>
          </tr>
        </thead>

        <tbody>
          {getting_top_deposit ? (
            <>
              <tr>
                <td colSpan={99} className='text-center py-5 bg-teal-50'>
                  <LoadingIndicator colorScheme='dark' />
                </td>
              </tr>
            </>
          ) : (
            top_deposit.map(item => (
              <tr
                key={`${item._id.username}-${item._id.website}`}
                className='opacity-60'
              >
                <td className='bg-teal-100 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6'>
                  {item._id.username}
                </td>

                <td
                  className={classNames(
                    'bg-teal-100 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-center',
                    selectedWeb ? 'hidden' : ''
                  )}
                >
                  {item._id.website}
                </td>

                <td className='bg-teal-100 py-2 px-4 first-of-type:pl-6 last-of-type:pr-6 text-right font-mono font-light'>
                  {thousandSeparator({ value: Math.round(item.deposit) })}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PlayerTopDeposit
